import {
    Aggregation,
    aggregationsToMap,
    fromAggregationsQuery,
} from 'src/components/CategoryListingPage/utils/cleanAggregations';
import { HIDE_FILTERS_COUNT } from 'src/constants/aggregations';
import { initialData } from 'src/components/LayeredNav/LayeredNavQueryProvider';
import { ApolloError } from 'apollo-client';
import { categoryProducts as CategoryProducts } from 'src/queries/__generated__/categoryProducts';

export const parseCategoryProduct = (loading: boolean, error?: ApolloError, data?: CategoryProducts) => {
    if (!data?.productAggregations?.aggregations) return { ...initialData, loading, error };

    const output = fromAggregationsQuery(data.productAggregations.aggregations);

    const outputWithoutTags = output.filter(
        (el) =>
            el.attribute_code !== 'product_tags' &&
            el.attribute_code !== 'export_filter_product_type' &&
            el.count &&
            el.count > HIDE_FILTERS_COUNT,
    );

    const mappedAggregations = aggregationsToMap(output);

    const highlightedAggregations: Aggregation[] = [];
    const length = outputWithoutTags.length > 2 ? 3 : outputWithoutTags.length;

    for (let i = 0; i < length; i++) {
        highlightedAggregations.push(outputWithoutTags[i]);
    }

    return {
        loading,
        error,
        aggregations: outputWithoutTags,
        mappedAggregations,
        highlightedAggregations,
    };
};
