import React from 'react';
import { useDeps } from 'src/hooks/useDeps';
import { useQuery } from '@apollo/react-hooks';

import {
    shippingGroup_shippingGroup_items_attribute_options,
    shippingGroup,
} from '../../queries/__generated__/shippingGroup';
import { shippingGroup as shippingGroupQuery } from '../../queries/shippingGroup.graphql';
import { upsellSKU as upsellSKUQuery } from '../../queries/upsellSKU.graphql';
import { upsellSKU } from '../../queries/__generated__/upsellSKU';

import classes from './Main.scss';

type MainProps = Record<string, unknown>;

type MainContextInitialProps = {
    shippingGroupList: shippingGroup_shippingGroup_items_attribute_options[];
    upsellSKUdata: any;
};

export const MainContext = React.createContext<MainContextInitialProps>({ shippingGroupList: [], upsellSKUdata: [] });

export const MainProvider: React.FC<MainProps> = React.memo((props) => {
    /**
     * Get meta data of Shipping Groups
     */
    const { data: dataShippingGroup } = useQuery<shippingGroup>(shippingGroupQuery, {
        ssr: useDeps().env.SSR_GQL,
    });

    const shippingGroupList: shippingGroup_shippingGroup_items_attribute_options[] =
        dataShippingGroup?.shippingGroup.items[0].attribute_options;

    /**
     * Get SKU of upsell items
     */
    const { data: dataUpsellSKU } = useQuery<upsellSKU>(upsellSKUQuery, {
        ssr: useDeps().env.SSR_GQL,
    });

    const upsellSKUdata = dataUpsellSKU && dataUpsellSKU.cartUpsell && dataUpsellSKU.cartUpsell.map((el) => el?.sku);

    return (
        <MainContext.Provider value={{ shippingGroupList, upsellSKUdata }}>
            <main className={classes.root}>{props.children}</main>
        </MainContext.Provider>
    );
});
