import React, { useContext, useMemo } from 'react';
import { AggregationQueryContext } from 'src/components/CategoryListingPage/AggregationQueryProvider';
import { useProductFilters } from 'src/components/CategoryListingPage/hooks/useProductFilters';
import { useQuery } from '@apollo/react-hooks';
import { categoryProducts, categoryProductsVariables } from 'src/queries/__generated__/categoryProducts';
import { Aggregation, MappedAggregations } from 'src/components/CategoryListingPage/utils/cleanAggregations';
import { ApolloError } from 'apollo-client';
import { parseCategoryProduct } from 'src/util/parseCategoryProduct';

import categoryProductsQuery from '../../queries/categoryProducts.graphql';

export interface LayeredNavQueryContextType {
    aggregations: Aggregation[];
    mappedAggregations: MappedAggregations | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    highlightedAggregations: Aggregation[];
}

export const initialData = {
    aggregations: [],
    mappedAggregations: undefined,
    loading: false,
    error: undefined,
    highlightedAggregations: [],
};

export const LayeredNavQueryContext = React.createContext<LayeredNavQueryContextType>(initialData);

export const LayeredNavQueryProvider: React.FC<{ id: number }> = React.memo((props) => {
    const { mappedAggregations } = useContext(AggregationQueryContext);
    const { initialQueryVars } = useProductFilters(props.id, mappedAggregations);
    const { loading, error, data } = useQuery<categoryProducts, categoryProductsVariables>(categoryProductsQuery, {
        variables: { ...initialQueryVars, includeAggregations: true },
        ssr: false,
    });

    const parsedCategoryProduct = useMemo(() => parseCategoryProduct(loading, error, data), [loading, error, data]);

    return (
        <LayeredNavQueryContext.Provider value={parsedCategoryProduct}>
            {props.children}
        </LayeredNavQueryContext.Provider>
    );
});
