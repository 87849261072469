import React, { useEffect } from 'react';

import './TrustPilot.scss';

type Props = {
    smallWidget?: boolean;
};

export const TrustPilot: React.FC<Props> = ({ smallWidget }) => {
    const ref = React.useRef(null);

    useEffect(() => {
        if ((window as any).Trustpilot) {
            (window as any).Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <div
            ref={ref}
            className={smallWidget ? 'trustpilot-widget small' : 'trustpilot-widget'}
            data-locale="en-GB"
            data-template-id="5406e65db0d04a09e042d5fc"
            data-businessunit-id="4d814a4200006400050f27ff"
            data-theme="light"
        >
            <a href="https://uk.trustpilot.com/review/www.workwearexpress.com" rel="noopener"></a>
        </div>
    );
};
