import EmailValidator from 'email-validator';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

export function basicEmailValidation(text = `Enter a valid email address e.g. example@email.com`) {
    return (value) => {
        if (!EmailValidator.validate(value)) {
            return text;
        }
    };
}

export function basicPresenceValidation(text = `This is a required field`) {
    return (value) => {
        return !value ? text : undefined;
    };
}

export function basicMaxLengthValidation(maxLength = 10, text = `Cannot be more than ${maxLength} characters`) {
    return (value) => {
        if (!value) return undefined;
        if (typeof value === 'string' && value.length > maxLength) {
            return text;
        }
        return undefined;
    };
}

export function basicLengthValidationWithText(
    minLength,
    maxLength,
    fieldText = `This is a required field`,
    textForMaxLength = `Cannot be more than ${maxLength} characters`,
    textForMinLength = `Cannot be less than ${minLength} characters`,
) {
    return (value) => {
        const valueWithoutSpecialChars = value?.replace(/[\W_]/g, '');
        if (!valueWithoutSpecialChars) return fieldText;
        if (typeof valueWithoutSpecialChars === 'string' && valueWithoutSpecialChars.length < minLength) {
            return textForMinLength;
        }
        if (typeof valueWithoutSpecialChars === 'string' && valueWithoutSpecialChars.length > maxLength) {
            return textForMaxLength;
        }
        return undefined;
    };
}

export function acmePasswordValidation(
    text = 'Enter at least 8 characters',
    invalid = 'Must include a number and a letter',
) {
    // Minimum length of this field must be equal or greater than 8 symbols. Leading and trailing spaces will be ignored.
    // Minimum of different classes of characters in password is 3. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.
    return (value) => {
        let counter = 0;
        const passwordMinLength = 8;
        const passwordMinCharacterSets = 2;
        const pass = value || ''.trim();

        if (pass.length < passwordMinLength) {
            return text;
        }

        if (pass.match(/\d+/)) {
            counter++;
        }

        if (pass.match(/[A-z]+/)) {
            counter++;
        }

        if (counter < passwordMinCharacterSets) {
            return invalid;
        }
    };
}

export const matchValidation = (password1, password2) => {
    return password1 !== password2 ? `pwMatch` : undefined;
};

const passwordValidation = (password1, password2) => {
    return acmePasswordValidation('minlength', 'validPassword')(password1) || matchValidation(password1, password2);
};

export const validatePassword = (value, values) => passwordValidation(value, values.confirmation);
export const validateConfirm = (value, values) => passwordValidation(value, values.password);

export function telephoneValidation(text = 'Enter a valid phone number', maxlength = 'Enter a maximum of 11 numbers') {
    const re = /^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/;
    return (value) => {
        const result = re.test(value);

        if (!result) {
            return text;
        }

        if (!value || value.length > 11) {
            return maxlength;
        }
    };
}

export function makeCountryPostcodeValidation(countryCode) {
    const message = `Please enter a valid '${countryCode}' postcode.`;

    if (countryCode === 'GB') {
        countryCode = 'UK';
    }

    return () => {
        return (val) => {
            if (postcodeValidatorExistsForCountry(countryCode) && !postcodeValidator(val, countryCode)) {
                return message;
            }
        };
    };
}

/**
 * This regex is provided by the client.
 * It's not meant to be an exhaustive check, but rather
 * a simple 'format' style check for UX.
 */
const POSTCODE_VALIDATION_REGEX = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]?\ ?[0-9]{1}[A-Z]{2}$/i;
export function postcodeValidation(text = 'Please enter a valid postcode') {
    return (val) => {
        const result = val && POSTCODE_VALIDATION_REGEX.test(val.trim());
        if (!result) {
            return text;
        }
    };
}

/**
 * Checks that order number is valid.
 */
const ORDER_NUMBER_VALIDATION_REGEX = /^SO[0-9]{4,}/i;
export function orderNumberValidation(text = 'Please enter a valid order number') {
    return (val) => {
        const result = val && ORDER_NUMBER_VALIDATION_REGEX.test(val.trim());
        if (!result) {
            return text;
        }
    };
}

/**
 * Checks that strings work for price inputs.
 */
export function isPriceDecimal(value: any): boolean {
    return /^(\d+?)(\.[\d]{1,2})?$/.test(String(value).trim());
}

function isFloat(value: any): boolean {
    return /^(\d+?)(\.[\d]+)?$/.test(String(value).trim());
}

/**
 * Accept any input and convert to a positive integer
 * @param value
 */
export function qtyRoundDown(value: any): number {
    const num = toNumber(value);
    return Math.floor(num);
}

function toNumber(value): number {
    if (isPositiveInteger(value)) {
        return parseFloat(value) || 1;
    }
    if (isFloat(value)) {
        return parseFloat(value) || 1;
    }
    if (isPriceDecimal(value)) {
        return parseFloat(value) || 1;
    }
    return 1;
}

function isPositiveInteger(n) {
    return n >>> 0 === parseFloat(n);
}

// Allow only numbers
export function allowOnlyNumbers(evt) {
    const theEvent = evt || window.event;
    let key;
    let elementToTest;
    // Handle paste
    if (theEvent.type === 'paste') {
        key = evt.clipboardData.getData('text/plain');
    } else {
        // Handle key press
        key = theEvent.keyCode || theEvent.which;
        elementToTest = String.fromCharCode(key);
    }
    const regex = /[0-9]/;
    if (!regex.test(elementToTest)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
}
