import React from 'react';
import classnames from 'classnames';

import { Spinner } from '../../Layout/Spinner';

import classes from './Search.scss';

type Props = Record<string, unknown>;

export const SearchPlaceholder: React.FC<Props> = React.memo(() => {
    return (
        <div
            data-test-id="SearchPlaceholder"
            className={classnames({
                [classes.searchResults]: true,
                [classes.searchResultsActive]: true,
            })}
        >
            <div className={classes.searchResultsSide}>
                <p>...</p>
            </div>
            <div className={classes.searchResultsMain}>
                <div className={classes.searchResultsMainLoader}>
                    <Spinner showText={true} />
                </div>
                <div className={classes.searchResultsFooter}>
                    <span>... results</span>
                </div>
            </div>
        </div>
    );
});
