import React, { useEffect } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import classnames from 'classnames';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import classes from './Modal.scss';

type Props = {
    isOpen?: boolean;
    onDismiss?();
    type?: 'sidebar' | 'popup' | 'sidebar-small' | 'narrow' | 'popup-wide' | 'wide' | 'transparent' | 'very-wide';
    noAnimation?: boolean;
    className?: string;
    bottomSpacing?: boolean;
};

export const Modal: React.FC<Props> = ({
    isOpen,
    onDismiss,
    className,
    type,
    noAnimation,
    bottomSpacing = false,
    children,
}) => {
    return (
        <DialogOverlay
            onDismiss={onDismiss}
            isOpen={isOpen}
            allowPinchZoom={true}
            className={classnames(classes.modalOverlay, {
                [classes.modalOverlay__noAnimation]: noAnimation,
                [classes.bottomSpacing]: bottomSpacing,
            })}
            data-test-id="Modal"
        >
            <DialogContent
                className={classnames(classes.modalContainer, {
                    [classes.modalContainerPopup]: type === 'popup',
                    [classes.modalContainerPopupWide]: type === 'popup-wide',
                    [classes.modalContainerPopupNarrow]: type === 'narrow',
                    [classes.modalContainerSidebar]: type === 'sidebar' || type === 'sidebar-small',
                    [classes.modalContainerSidebarSmall]: type === 'sidebar-small',
                    [classes.modalContainerWide]: type === 'wide',
                    [classes.modalContainerVeryWide]: type === 'very-wide',
                    [classes.modalContainerTransparent]: type === 'transparent',
                })}
                aria-label="Modal Content"
            >
                <div className={classnames(className, classes.modalContent)}>{children}</div>
                <ScrollLock />
            </DialogContent>
        </DialogOverlay>
    );
};

Modal.defaultProps = {
    isOpen: false,
    type: 'popup',
};

const ScrollLock: React.FC = () => {
    useEffect(() => {
        const $scrollableElement = document.querySelector(`[data-reach-dialog-overlay]`);
        if ($scrollableElement) {
            disablePageScroll($scrollableElement);
        }
        return () => {
            if ($scrollableElement) {
                enablePageScroll($scrollableElement);
            }
        };
    }, []);
    return null;
};
