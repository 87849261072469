import React from 'react';
import classnames from 'classnames';
import './Container.scss';

type ContainerProps = {
    className?: string;
    variant?: 'normal' | 'narrow' | 'modal-narrow' | 'small' | 'mobile-medium-padding' | 'mobile-no-padding';
    width?: 'page';
};

export const Container: React.FC<ContainerProps> = (props) => {
    return (
        <div
            className={classnames({
                ['container']: true,
                ['containerNarrow']: props.variant === 'narrow',
                ['containerSmall']: props.variant === 'small',
                ['containerModalNarrow']: props.variant === 'modal-narrow',
                ['containerMobileMediumPadding']: props.variant === 'mobile-medium-padding',
                ['containerNoMobilePadding']: props.variant === 'mobile-no-padding',
                [props.className || '']: true,
            })}
        >
            {props.children}
        </div>
    );
};

Container.defaultProps = {
    variant: 'normal',
    width: 'page',
};
