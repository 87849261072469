import { Message } from '@wearejh/m2-pwa-engine/lib/types';

import { Actions } from './app.actions';

export enum OverlayType {
    Cart = 'Cart',
    User = 'User',
    Nav = 'Nav',
    Checkout = 'Checkout',
}

export type AppState = {
    openNav: boolean;
    openSearch: boolean;
    openOverlay: boolean;
    canOpen: boolean;
    openSubNavs: number[];
    cookieBannerOpen: boolean;
    renderEnv: 'server' | 'browser';
    messages: Message[];
};

export const initialState: AppState = {
    openNav: false,
    openSearch: false,
    openOverlay: false,
    canOpen: true,
    openSubNavs: [],
    cookieBannerOpen: false,
    renderEnv: 'server',
    messages: [],
};

export function appReducer(state = initialState, action: Actions): AppState {
    switch (action.type) {
        case 'App.OpenNav': {
            const current = state.openNav;
            return {
                ...state,
                openNav: current ? false : true,
                openOverlay: current ? false : true,
                openSearch: false,
            };
        }
        case 'App.OpenSubNav': {
            if (!state.canOpen) {
                return state;
            }
            return {
                ...state,
                openSubNavs: action.payload,
                // openOverlay: true,
            };
        }
        case 'App.EnableNav': {
            return {
                ...state,
                canOpen: true,
            };
        }
        case 'App.CloseSubNav': {
            return {
                ...state,
                openSubNavs: state.openSubNavs.filter((x) => x !== action.payload),
            };
        }
        case 'App.CloseAllNavs': {
            return {
                ...state,
                openSubNavs: [],
                openNav: false,
                canOpen: false,
                openOverlay: false,
            };
        }
        case 'App.ToggleSearch': {
            if (action.payload) {
                return {
                    ...state,
                    openSubNavs: [],
                    openSearch: true,
                    openNav: false,
                    openOverlay: false,
                };
            }
            return {
                ...state,
                openSubNavs: [],
                openSearch: false,
                canOpen: true,
            };
        }
        case 'App.ShowCookieBanner': {
            return {
                ...state,
                cookieBannerOpen: true,
            };
        }
        case 'App.HideCookieBanner': {
            return {
                ...state,
                cookieBannerOpen: false,
            };
        }
        case 'App.AddMessages': {
            return {
                ...state,
                messages: action.payload,
            };
        }
        case 'App.ClearMessages': {
            return {
                ...state,
                messages: [],
            };
        }
        default:
            return state;
    }
}
