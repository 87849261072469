import { switchMap } from 'rxjs/operators';
import { concat, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { execute } from 'swagger/ts/WorkwearExpressCustomerServiceLoginAuthServiceV1LoginPost';
import { Deps } from 'src/types/global-types';
import { catchableUnauthenticated } from '@wearejh/m2-pwa-user/lib/utils/user.utils';

import { CspActions, CspMsg, TypeMap } from '../csp.register';

export function cspLogin(action$: Observable<any>, _state$: Observable<any>, deps: Deps) {
    return action$.pipe(
        ofType<CspActions, TypeMap['Csp.Submit']>('Csp.Submit'),
        switchMap(({ payload }) => {
            const ajax = execute({ username: payload.username, password: payload.password }, deps);
            return ajax.pipe(
                switchMap((resp) => {
                    return concat(of(CspMsg('Csp.Success', resp)), of(CspMsg('Csp.Persist')), of(deps.push('/')));
                }),
                catchableUnauthenticated(({ error }) => {
                    return of(CspMsg('Csp.Error', error));
                }),
            );
        }),
    );
}
