import { Observable, concat, of, race, asyncScheduler, scheduled } from 'rxjs';
import { switchMap, mergeMap, map, take, takeUntil } from 'rxjs/operators';
import { execute as createAccountAjax } from 'swagger/ts/CustomerAccountManagementV1CreateAccountPost';
import { ofType } from 'redux-observable';
import { EpicDeps } from '@wearejh/m2-pwa-engine/lib/types';
import { catchable } from '@wearejh/swagger-rxjs/utils/ajax-helpers';
import { LOCATION_CHANGE } from '@wearejh/m2-pwa-engine/lib/router';
import { Msg, TypeMap, UserActions } from '@wearejh/m2-pwa-user/lib/user.actions';

import { CspActions, TypeMap as CspTypeMap, CspCustomerSignInMsg } from '../cspCustomerSignin.register';

export function cspCustomerRegisterEpic(action$: Observable<any>, _state$, deps: EpicDeps) {
    return action$.pipe(
        ofType<CspActions, CspTypeMap['CspUser.Register']>('CspUser.Register'),
        switchMap(({ payload }) => {
            const { email, firstname, lastname, extension_attributes, cspToken = {} } = payload;

            const ajax$ = createAccountAjax(
                {
                    customer: {
                        firstname,
                        lastname,
                        email,
                        extension_attributes,
                    },
                },
                deps,
            );

            return ajax$.pipe(
                mergeMap(() => {
                    return concat(
                        // now sign the user in
                        of(
                            CspCustomerSignInMsg('CspUser.SignIn', {
                                emailAddress: email,
                                cspToken: String(cspToken),
                            }),
                        ),

                        // now listen for the result of the sign-in
                        race(
                            // if the account + sign-in was successful
                            action$.pipe(
                                ofType<UserActions>('User.SignInSuccess'),
                                mergeMap(() => {
                                    return scheduled(of(Msg('User.RegisterSuccess')), asyncScheduler);
                                }),
                            ),
                            action$.pipe(
                                ofType<UserActions, TypeMap['User.SignInError']>('User.SignInError'),
                                map(({ payload }) => Msg('User.RegisterError', payload)),
                            ),
                        ).pipe(take(1), takeUntil(action$.pipe(ofType(LOCATION_CHANGE)))),
                    );
                }),
                catchable(({ error }) => of(Msg('User.RegisterError', error))),
            );
        }),
    );
}
