import React, { useContext, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
    categoryProducts,
    categoryProducts_categoryProducts_page_info,
    categoryProductsVariables,
} from 'src/queries/__generated__/categoryProducts';
import { useCurrencyChange } from 'src/components/AppShell/ConfigProvider';
import { ApolloError } from 'apollo-client';
import { createDebug } from 'src/util/debug';
import { useDeps } from 'src/hooks/useDeps';
import { parseCategoryProduct } from 'src/util/parseCategoryProduct';
import { LayeredNavQueryContextType } from 'src/components/LayeredNav/LayeredNavQueryProvider';

import categoryProductsQuery from '../../queries/categoryProducts.graphql';
import { useSearch } from '../../hooks/router-hooks';

import { FilterVars, useProductFilters } from './hooks/useProductFilters';
import { productListItemsFromQuery, ProductListItem } from './utils/productListItem';
import { AggregationQueryContext } from './AggregationQueryProvider';
import { CategoryQueryContext } from './CategoryQueryProvider';

const debug = createDebug('ProductListQueryProvider');

export interface PageInfo {
    current_page: number;
    total_pages: number;
    page_size: number;
}
const defaultPageInfo: PageInfo = { current_page: 0, page_size: 0, total_pages: 0 };

type ContextType = {
    products: ProductListItem[];
    totalCount: number;
    pageInfo: PageInfo;
    loading: boolean;
    error: ApolloError | undefined;
    createPageUrl: (num: number) => string;
    setSortOrder: (param: string, order: string) => void;
    clearSort: () => void;
    hasData: boolean;
    initialQueryVars: FilterVars;
    parsedCategoryProduct: LayeredNavQueryContextType;
};

const defaultState = {
    products: [],
    totalCount: 0,
    loading: true,
    error: undefined,
    hasData: false,
    pageInfo: defaultPageInfo,
    createPageUrl: (..._args: any[]) => {
        // eslint-disable-next-line no-console
        console.log('setPage not implemented', _args);
        return '/';
    },
    setSortOrder: (..._args: any[]) => {
        // eslint-disable-next-line no-console
        console.log('setSortOrder not implemented', _args);
    },
    clearSort: (..._args: any[]) => {
        // eslint-disable-next-line no-console
        console.log('clearSort not implemented', _args);
    },
    initialQueryVars: {},
    parsedCategoryProduct: {
        aggregations: [],
        mappedAggregations: undefined,
        loading: false,
        error: undefined,
        highlightedAggregations: [],
    },
};

export const ProductListQueryContext = React.createContext<ContextType>(defaultState);

const EMPTY_ARR = [];

let cachedDataFromApi: categoryProducts | undefined = undefined;

export const ProductListQueryProvider: React.FC<{ id: number }> = React.memo((props) => {
    const currentPath = useRef<string | undefined>();
    const cachedDataRef = useRef<categoryProducts | undefined>();

    const { mappedAggregations } = useContext(AggregationQueryContext);
    const { bestSellers } = useContext(CategoryQueryContext);
    const { initialQueryVars, createPageUrl, setSortOrder, clearSort, pathname } = useProductFilters(
        props.id,
        mappedAggregations,
    );

    if (currentPath.current === undefined) {
        currentPath.current = pathname;
    }

    debug('initialQueryVars = %o', initialQueryVars);

    cachedDataFromApi = cachedDataRef.current;

    const { loading, error, data, refetch } = useQuery<categoryProducts, categoryProductsVariables>(
        categoryProductsQuery,
        {
            variables: {
                ...initialQueryVars,
                includeProducts: true,
                includeAggregations: true,
            },
            ssr: useDeps().env.SSR_GQL,
            skip: currentPath.current !== pathname,
        },
    );

    const parsedCategoryProduct = useMemo(() => parseCategoryProduct(loading, error, data), [loading, error, data]);

    const search = useSearch();
    cachedDataRef.current = data;

    useCurrencyChange(refetch);

    const api: ContextType = useMemo(() => {
        const dataFromApi = data === undefined ? cachedDataFromApi : data;
        if (!dataFromApi || !dataFromApi.categoryProducts) {
            return defaultState;
        }
        const items = dataFromApi.categoryProducts.items || EMPTY_ARR;
        const totalCount = dataFromApi.categoryProducts.total_count || 0;
        const pageInfo = cleanPageInfo(dataFromApi.categoryProducts.page_info);
        const products = productListItemsFromQuery(items, bestSellers, search);
        return {
            ...defaultState,
            loading,
            error,
            products,
            pageInfo,
            totalCount,
            createPageUrl,
            setSortOrder,
            clearSort,
            initialQueryVars,
            parsedCategoryProduct,
        };
    }, [
        data,
        bestSellers,
        search,
        loading,
        error,
        createPageUrl,
        setSortOrder,
        clearSort,
        initialQueryVars,
        parsedCategoryProduct,
    ]);

    return (
        <ProductListQueryContext.Provider value={api || cachedDataFromApi}>
            {props.children}
        </ProductListQueryContext.Provider>
    );
});

export const cleanPageInfo = (info: categoryProducts_categoryProducts_page_info | null) => {
    if (!info) return defaultPageInfo;
    return {
        current_page: info.current_page || 0,
        page_size: info.page_size || 0,
        total_pages: info.total_pages || 0,
    };
};
