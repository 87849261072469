import React, { useContext } from 'react';

import { ButtonLink } from '../Button/ButtonLink';
import { Button } from '../Button/Button';
import { ProductListQueryContext } from '../CategoryListingPage/ProductListQueryProvider';

import classes from './Modal.scss';

type Props = {
    onDismiss?(...args: any[]): void;
    cancelText?: string;
    buttonText?: string;
    buttonUrl?: string;
    confirmBtn?: React.ReactNode;
    controlScroll?: boolean;
    showCount?: boolean;
};

export const ModalFooter: React.FC<Props> = (props) => {
    const { confirmBtn, onDismiss, cancelText, buttonText, buttonUrl, showCount } = props;
    const { totalCount } = useContext(ProductListQueryContext);
    const shouldDisplayButtonLink = buttonText && buttonUrl;
    return (
        <footer className={showCount ? classes.modalFooterWithTotalCount : classes.modalFooter}>
            <div className={classes.modalEscape}>
                {shouldDisplayButtonLink && <ButtonLink to={buttonUrl || '/'}>{buttonText}</ButtonLink>}
                {onDismiss && (
                    <Button
                        type="button"
                        variant={showCount ? 'secondary' : 'reset'}
                        onClick={() => {
                            if (props.controlScroll) {
                                return onDismiss(true);
                            }
                            return onDismiss();
                        }}
                    >
                        {showCount ? <span>Display {totalCount} products</span> : <span>{cancelText || 'Cancel'}</span>}
                    </Button>
                )}
            </div>
            {confirmBtn !== undefined && confirmBtn}
        </footer>
    );
};
