import React from 'react';
import { Message } from '@wearejh/m2-pwa-engine/lib/types';
import { Svg } from 'src/components/Svg/Svg';

import classes from './Messages.scss';

type Props = {
    messages: Message[];
};

export const Messages: React.FC<Props> = (props) => {
    return (
        <div className={classes.root} data-test-id="Messages">
            {props.messages.map((x) => {
                return (
                    <div key={x.text} className={classes.message} data-message-type={x.type}>
                        {x.type !== 'Success' && <Svg name="alert" />}
                        {x.type == 'Success' && <Svg name="check-circle" />}
                        <div>
                            <strong>{formatMsgText(x.text)}</strong>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const formatMsgText = (text: string) => {
    let newText = text;
    if (newText.includes('GraphQL error:')) {
        newText = newText.replace('GraphQL error: ', '');
    }
    return newText;
};
