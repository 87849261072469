export function replaceUrlParams(path: string, obj: { [index: string]: string } = {}) {
    return path.replace(/\{(.+?)\}/g, (_args, name) => {
        return encodeURIComponent(obj[name] || '');
    });
}

export function replaceUrlParts(path: string, obj: { [index: string]: string | number } = {}) {
    // /projects/:id
    // /projects/32

    return Object.keys(obj).reduce((acc: string, key: string) => {
        return acc.replace(`:${key}`, String(obj[key]));
    }, path);
}

export function simpleTemplate(path: string, obj: { [index: string]: string } = {}) {
    return path.replace(/{(.+?)}/g, (_args, name) => {
        return obj[name] || '';
    });
}
