import React from 'react';
import { RxValidateFn } from '@wearejh/rx-form/lib/types';
import { Text } from '@wearejh/rx-form';
import { useFieldError } from '@wearejh/rx-form/lib/hooks/useFieldError';
import classnames from 'classnames';

import classes from './Forms.scss';
import { ErrorFor } from './ErrorFor';

type InputProps = {
    id?: string;
    field?: string;
    label?: string;
    type?: 'text' | 'password' | 'number';
    placeholder?: string;
    validate?: RxValidateFn;
    notify?: string[];
    autoFocus?: boolean;
    required?: boolean;
    disableAutoComplete?: boolean;
    optional?: boolean;
    onChange?: (...args: any[]) => void;
    minLength?: number;
    disabled?: boolean;
};

export const Input: React.FC<InputProps> = ({
    label,
    id,
    field,
    placeholder,
    validate,
    type,
    notify,
    autoFocus,
    required,
    disableAutoComplete,
    optional,
    onChange,
    minLength,
    disabled = false,
}) => {
    const isNotValid = useFieldError(field || '');
    return (
        <>
            {label && (
                <label
                    htmlFor={id}
                    className={classnames({
                        [classes.isNotValid]: isNotValid,
                    })}
                >
                    {label}
                    {required && <span className="required">{' *'}</span>}
                    {optional && <span className="optional">{' (Optional)'}</span>}
                </label>
            )}

            <Text
                field={field || ''}
                id={id || field}
                type={type}
                placeholder={placeholder}
                validate={validate}
                notify={notify}
                autoFocus={autoFocus}
                autoComplete={disableAutoComplete ? 'off' : 'on'}
                onChange={onChange}
                minLength={minLength}
                disabled={disabled}
            />
            <ErrorFor field={field || ''} />
        </>
    );
};

Input.defaultProps = {
    type: 'text',
    required: false,
};
