import React from 'react';
import classnames from 'classnames';

import { Button } from '../Button/Button';
import { Svg } from '../Svg/Svg';

import classes from './Controls.scss';

export const HeaderStoreButton: React.FC<{ onClick(): any; text: string; active?: boolean }> = React.memo((props) => {
    return (
        <Button
            className={classnames({
                [classes.controlButton]: true,
                [classes.controlButtonActive]: props.active,
            })}
            variant="reset"
            onClick={props.onClick}
        >
            {props.text} <Svg name="arrow-down" />
        </Button>
    );
});
