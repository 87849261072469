import React from 'react';
import classnames from 'classnames';

import { SvgIconNames } from './icons-types';

type SvgProps = {
    name?: SvgIconNames;
    color?: 'blue' | 'primary-light' | 'white' | 'black' | 'secondary' | 'success' | 'failure' | 'grey' | 'orange';
    className?: string;
    role?: string;
};

export const Svg: React.FC<SvgProps> = ({ name, color, role, className, ...rest }) => (
    <svg
        className={classnames({
            ['svg']: true,
            [color ? `color--${color}` : '']: true,
            [className || '']: true,
        })}
        role={role}
        {...rest}
    >
        <use xlinkHref={`#svg-${name}`} />
    </svg>
);
