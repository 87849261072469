import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "Get customer by Customer ID.";
export const method = "GET";
export const operationId = "customerCustomerRepositoryV1GetByIdGet";
export const path = "/V1/customers/me";
export type Response = Response200 | Response400 | Response401 | Response500 | ResponseDefault;
export type Response200 = Definitions.CustomerDataCustomerInterface;
export type RespKeys = (keyof Definitions.CustomerDataCustomerInterface)[];
export type Response400 = Definitions.ErrorResponse;
export type Response401 = Definitions.ErrorResponse;
export type Response500 = Definitions.ErrorResponse;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "getJSON">;
export function execute<T extends RespKeys | undefined>(effects: MinimalEffects, fields?: T): T extends RespKeys ? Observable<Pick<Definitions.CustomerDataCustomerInterface, T[number]>> : Observable<Response200> {
    return effects.getJSON(effects.apiUrl(operationId)(path, undefined, fields), effects.restHeaders({})) as any;
}
