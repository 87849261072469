export const getBackgroundColorClassName = (classes, bg?: string) => ({
    [classes.bgWhite]: bg === 'white',
    [classes.bgWhiteAndOrange]: bg === 'whiteAndOrange',
    [classes.bgOrange]: bg === 'orange',
    [classes.bgLightOrange]: bg === 'light-orange',
    [classes.bgGrey]: bg === 'grey',
    [classes.bgGreen]: bg === 'green',
    [classes.bgBlack]: bg === 'black',
    [classes.alternateBgBlack]: bg === 'alternateBlack',
    [classes.bgRed]: bg === 'red',
});
