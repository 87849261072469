import { concat, Observable, of } from 'rxjs';
import { catchError, mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { EpicDeps } from '@wearejh/m2-pwa-engine/lib/types';
import { AppendUserState, UserSession } from '@wearejh/m2-pwa-user';
import { Actions, Msg, TypeMap } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import { AppendCartState } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import { fetchCart } from 'src/features/cart/epics/fetchCart';
import { fetchMineCartId } from '@wearejh/m2-pwa-cart-gql/lib/utils/fetchMineCartId';
import { BasketMsg } from 'src/components/Basket/feature/basket.actions';
type State = AppendCartState & AppendUserState;

/**
 * This epic handles the fetching of all carts
 * @param action$
 * @param state$
 * @param deps
 */
export function cartFetch2(action$: Observable<any>, state$: Observable<State>, deps: EpicDeps): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['Cart.Fetch']>('Cart.Fetch'),
        withLatestFrom(state$.pipe(pluck('cart', 'cartId')), state$.pipe(pluck('user', 'session'))),
        switchMap(([{ payload }, cartId, userSession]) => {
            /**
             *
             * Possible states:
             *
             * 1. user, where a previous cart was retrieved and it's ID was sent in this actions payload
             * 2. user with a previous cart
             * 3. user with no previous cart
             * 4. guest with no previous cart
             * 5. guest with previous cart
             *
             */

            const ajax$ = (() => {
                if (userSession === UserSession.SignedIn) {
                    // if an ID was given in the action, use it first
                    if (payload && payload.cartId) {
                        return fetchCart(payload.cartId, deps);
                    }
                    // if the cartId is absent, lookup for the user first
                    if (!cartId) {
                        return fetchMineCartId(deps).pipe(mergeMap((id) => fetchCart(id, deps)));
                    }
                }
                // default is to use the ID from the store
                return fetchCart(cartId, deps);
            })();

            return ajax$.pipe(
                mergeMap((resp) => {
                    return of(Msg('Cart.FetchSuccess', resp), BasketMsg('Basket.Refresh'), Msg('Cart.Persist'));
                }),
                catchError((err) => {
                    return concat(of(Msg('Cart.FetchError', err), Msg('Cart.Reset')));
                }),
            );
        }),
    );
}
