import React from 'react';

import classes from './Helpers.scss';

type BlockProps = Record<string, unknown>;

export const Block: React.FC<BlockProps> = (props) => {
    return <div className={classes.block}>{props.children}</div>;
};

export const BlockHalfSpacing: React.FC<BlockProps> = (props) => {
    return <div className={classes.blockHalfSpacing}>{props.children}</div>;
};
