import React, { useContext, useEffect, useState } from 'react';
import { ConfigContext } from 'src/components/AppShell/ConfigProvider';
import { GoogleTagManager } from 'src/components/Scripts/GoogleTagManager';

import { LiveChat } from './LiveChat';

export const Scripts: React.FC = () => {
    const context = useContext(ConfigContext);
    const isLiveChat = context.liveChat.enabled;
    const isGtm = context.gtm.enabled;
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setTimeout(function () {
            setReady(true);
        }, 3000);
    }, []);

    return (
        <div>
            {ready && isLiveChat && <LiveChat enabled={context.liveChat.enabled} apiKey={context.liveChat.apiKey} />}
            {isGtm && <GoogleTagManager enabled={context.gtm.enabled} id={context.gtm.id} />}
        </div>
    );
};
