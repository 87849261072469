import React, { HTMLAttributes } from 'react';
import { Hydrator, ServerHydrator } from 'src/components/AppShell/Hydrator';
import { PropsWithChildren } from 'react';

let Loader: any = Hydrator;

if (typeof window === 'undefined') {
    Loader = ServerHydrator;
}

export function LazyHydrate(
    props: PropsWithChildren<{ load: () => any; delay?: number; minHeight?: string } & HTMLAttributes<any>>,
) {
    const { load, delay, minHeight, ...rest } = props;
    return <Loader load={props.load} delay={props.delay} minHeight={minHeight} {...rest} />;
}
