import { useState, useEffect } from 'react';

export const useViewport = (mobileWidth?: number) => {
    const [width, setWidth] = useState(0);
    const mobileViewport = mobileWidth || 700;
    const tabletViewport = 1024;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const isMobile = width <= mobileViewport;
    const isTablet = width >= tabletViewport;

    return { isMobile, isTablet };
};
