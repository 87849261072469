import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { CspCustomerSignInMsg } from 'src/components/CustomerServicePortal/feature/cspCustomerSignin.register';
import { CustomerDataCustomerExtensionInterface } from '@wearejh/swagger-rxjs/lib/Definitions';

import { AppendCSPState, CspMsg, CspSession } from '../feature/csp.register';

export type CspRegisterParams = {
    firstname: string;
    lastname: string;
    email: string;
    extension_attributes?: CustomerDataCustomerExtensionInterface & { [index: string]: any };
    cspToken: string;
};

const dataSelector = (s: AppendCSPState) => {
    return {
        firstName: s.csp.customerServiceRep?.user.first_name,
        lastName: s.csp.customerServiceRep?.user.last_name,
        email: s.csp.customerServiceRep?.user.email,
        selector: s.csp.customerServiceRep?.user.email,
        token: s.csp.customerServiceRep?.token,
    };
};

const cspSelector = (s: AppendCSPState) => s.csp;

export type UseCspStatusAPI = {
    isPending: boolean;
    firstName: string;
    lastName: string;
    email: string;
    token: string;
    signOut(event?: any);
    register(params: CspRegisterParams);
    messages: Message[];
    onSubmit(params: { username: string; password: string });
};

export function useCspLogin(): UseCspStatusAPI {
    const dispatch = useDispatch();
    const { status, messages } = useSelector(cspSelector, shallowEqual);
    const isPending = status === DataStatus.Pending;
    const { firstName, lastName, email, token } = useSelector(dataSelector, shallowEqual);
    const signOut = useCallback(
        (e) => {
            if (e && e.preventDefault) {
                e.preventDefault();
            }
            dispatch(CspMsg('Csp.SignOut'));
        },
        [dispatch],
    );
    const onSubmit = useCallback(
        (values: { username: string; password: string }) => {
            dispatch(CspMsg('Csp.Submit', values));
        },
        [dispatch],
    );

    const register = useCallback(
        (values: CspRegisterParams) => {
            dispatch(CspCustomerSignInMsg('CspUser.Register', values));
        },
        [dispatch],
    );

    return {
        isPending,
        firstName,
        lastName,
        email,
        token,
        signOut,
        messages,
        onSubmit,
        register,
    };
}

const signedInSelector = (x: AppendCSPState) => x.csp && x.csp.session === CspSession.SignedIn;
const tokenSelector = (x: AppendCSPState) => (x.csp && x.csp.customerServiceRep.token) || null;

export function useCspIsLoggedIn(): boolean {
    return useSelector(signedInSelector, shallowEqual);
}

export function useCspLoggedInToken(): null | string {
    return useSelector(tokenSelector, shallowEqual);
}

export function noop(name: string) {
    return () => {
        // eslint-disable-next-line no-console
        console.log(`${name} is missing - did you include the register function for it?`);
        return undefined;
    };
}
