/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The list of available currency codes
 */
export enum CurrencyEnum {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZM = "AZM",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BUK = "BUK",
  BWP = "BWP",
  BYR = "BYR",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHE = "CHE",
  CHF = "CHF",
  CHW = "CHW",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EEK = "EEK",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEK = "GEK",
  GEL = "GEL",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GQE = "GQE",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LSM = "LSM",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIC = "NIC",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RHD = "RHD",
  ROL = "ROL",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SKK = "SKK",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  STD = "STD",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMM = "TMM",
  TND = "TND",
  TOP = "TOP",
  TRL = "TRL",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VEB = "VEB",
  VEF = "VEF",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XCD = "XCD",
  XOF = "XOF",
  XPF = "XPF",
  YER = "YER",
  YTL = "YTL",
  ZAR = "ZAR",
  ZMK = "ZMK",
  ZWD = "ZWD",
}

/**
 * The list of available payment token types
 */
export enum PaymentTokenTypeEnum {
  account = "account",
  card = "card",
}

/**
 * This enumeration states whether a product stock status is in stock or out of stock
 */
export enum ProductStockStatus {
  IN_STOCK = "IN_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
}

/**
 * This enumeration indicates whether to return results in ascending or descending order
 */
export enum SortEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SubscriptionStatusesEnum {
  NOT_ACTIVE = "NOT_ACTIVE",
  SUBSCRIBED = "SUBSCRIBED",
  UNCONFIRMED = "UNCONFIRMED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
}

/**
 * This enumeration defines the entity type.
 */
export enum UrlRewriteEntityTypeEnum {
  BUNDLE_PRODUCT = "BUNDLE_PRODUCT",
  CATEGORY = "CATEGORY",
  CATEGORY_BRAND_PAGE = "CATEGORY_BRAND_PAGE",
  CATEGORY_LANDING_PAGE = "CATEGORY_LANDING_PAGE",
  CATEGORY_PERSONALISED = "CATEGORY_PERSONALISED",
  CMS_PAGE = "CMS_PAGE",
  CONFIGURABLE_PRODUCT = "CONFIGURABLE_PRODUCT",
  GROUPED_PRODUCT = "GROUPED_PRODUCT",
  PRODUCT = "PRODUCT",
  SIMPLE_PRODUCT = "SIMPLE_PRODUCT",
  VIRTUAL_PRODUCT = "VIRTUAL_PRODUCT",
  WEARER_BUNDLE_PRODUCT = "WEARER_BUNDLE_PRODUCT",
}

/**
 * Defines a filter that matches the input exactly.
 */
export interface FilterEqualTypeInput {
  eq?: string | null;
  in?: (string | null)[] | null;
}

/**
 * Defines a filter that performs a fuzzy search.
 */
export interface FilterMatchTypeInput {
  match?: string | null;
}

/**
 * Defines a filter that matches a range of values, such as prices or dates.
 */
export interface FilterRangeTypeInput {
  from?: string | null;
  to?: string | null;
}

/**
 * ProductAttributeFilterInput defines the filters to be used in the search. A
 * filter contains at least one attribute, a comparison operator, and the value
 * that is being searched for.
 */
export interface ProductAttributeFilterInput {
  apron_style?: FilterEqualTypeInput | null;
  bag_style?: FilterEqualTypeInput | null;
  blouses_style?: FilterEqualTypeInput | null;
  brand?: FilterEqualTypeInput | null;
  category_id?: FilterEqualTypeInput | null;
  colour_family?: FilterEqualTypeInput | null;
  description?: FilterMatchTypeInput | null;
  fastening_style?: FilterEqualTypeInput | null;
  fit_style?: FilterEqualTypeInput | null;
  footwear_attributes?: FilterEqualTypeInput | null;
  footwear_protection?: FilterEqualTypeInput | null;
  footwear_style?: FilterEqualTypeInput | null;
  garment_type?: FilterEqualTypeInput | null;
  gender?: FilterEqualTypeInput | null;
  headwear_attributes?: FilterEqualTypeInput | null;
  headwear_style?: FilterEqualTypeInput | null;
  hi_vis_attributes?: FilterEqualTypeInput | null;
  hi_vis_class?: FilterEqualTypeInput | null;
  hi_vis_style?: FilterEqualTypeInput | null;
  hoodie_type?: FilterEqualTypeInput | null;
  hoodies_attributes?: FilterEqualTypeInput | null;
  industrial_laundry?: FilterEqualTypeInput | null;
  jacket_type?: FilterEqualTypeInput | null;
  jackets_attributes?: FilterEqualTypeInput | null;
  knitwear_attributes?: FilterEqualTypeInput | null;
  knitwear_style?: FilterEqualTypeInput | null;
  leg_length?: FilterEqualTypeInput | null;
  material?: FilterEqualTypeInput | null;
  name?: FilterMatchTypeInput | null;
  neck_type?: FilterEqualTypeInput | null;
  overall_style?: FilterEqualTypeInput | null;
  overalls_attributes?: FilterEqualTypeInput | null;
  pockets_style?: FilterEqualTypeInput | null;
  ppe_attributes?: FilterEqualTypeInput | null;
  ppe_type?: FilterEqualTypeInput | null;
  price?: FilterRangeTypeInput | null;
  product_tags?: FilterEqualTypeInput | null;
  products_attributes?: FilterEqualTypeInput | null;
  products_sizing?: FilterEqualTypeInput | null;
  ris_3279_tom_standard?: FilterEqualTypeInput | null;
  safety_iso_2345?: FilterEqualTypeInput | null;
  scarf_style?: FilterEqualTypeInput | null;
  short_description?: FilterMatchTypeInput | null;
  short_style?: FilterEqualTypeInput | null;
  sku?: FilterEqualTypeInput | null;
  sleeve_length?: FilterEqualTypeInput | null;
  slip_resistance?: FilterEqualTypeInput | null;
  suiting_attributes?: FilterEqualTypeInput | null;
  sweatshirts_attributes?: FilterEqualTypeInput | null;
  thermal_layers_attributes?: FilterEqualTypeInput | null;
  tie_style?: FilterEqualTypeInput | null;
  towelling_type?: FilterEqualTypeInput | null;
  trouser_style?: FilterEqualTypeInput | null;
  trousers_attributes?: FilterEqualTypeInput | null;
  tshirts_attributes?: FilterEqualTypeInput | null;
  tunic_style?: FilterEqualTypeInput | null;
  tunics_attributes?: FilterEqualTypeInput | null;
  url_key?: FilterEqualTypeInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
