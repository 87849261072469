import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { useClickOutside } from '../../hooks/useClickOutside';

import classes from './Controls.scss';
import { HeaderStoreButton } from './HeaderStoreBtn';

/**
 * Allow Typescript to infer the type of T so that you
 * get auto-complete on the render function
 */
type Props<T> = {
    text?: string | null;
    items: T[];
    render(params: { item: T; index: number; close: () => void });
};

/**
 * @param props
 * @constructor
 */
export function HeaderStoreDropdown<T extends any>(props: PropsWithChildren<Props<T>>) {
    const { text, items, render, ...rest } = props;

    /**
     * State to handle the open/close of the dropdown
     */
    const [openDropdown, toggleDropdown] = useState(false);

    /**
     * Handle the click on the dropdown button
     */
    const onClick = useCallback(() => toggleDropdown((x) => !x), []);

    /**
     * Close helper for items to call if required
     */
    const close = useCallback(() => toggleDropdown(false), []);

    /**
     * Keep a ref on the outer div
     */
    const outerRef = useRef<HTMLDivElement | null>(null);

    /**
     * Track clicks outside, if they happen fire our callback
     */
    useClickOutside(outerRef, openDropdown, close);

    return (
        <div className={classes.control} ref={outerRef} {...rest}>
            <HeaderStoreButton text={props.text || '...'} onClick={onClick} active={openDropdown} />
            {openDropdown && (
                <ul className={classes.controlList}>
                    {props.items.map((item, index) => {
                        return (
                            <li key={`-${index}`} className={classes.controlListItem}>
                                {props.render({ item, index, close })}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
