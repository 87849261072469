import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import classnames from 'classnames';
import './RichText.scss';
import { ReviewStars } from 'src/components/Reviews/ReviewStars';

import { stripDomainForMedia } from '../../util/sanitizeLink';

const insertReviewStars = (string: string) => {
    let text = string;
    const regex = /(\{stars\:)([1-9]\d*(\.\d+)?)(\})/;
    const globalSearchRegex = new RegExp(regex, 'g');
    const matches = string.match(globalSearchRegex);

    matches &&
        matches.map((match, index) => {
            const [, , rating] = regex.exec(match) || [];

            text = text.replace(
                match,
                renderToStaticMarkup(<ReviewStars count={rating} id={`review-stars-${index}`} fill="#FFC121" />),
            );
        });

    return text;
};

const toHTML = (str) => {
    const fns = [stripDomainForMedia, insertReviewStars];
    return { __html: fns.reduce((acc, fn) => fn(acc), str) };
};
type RichTextProps = { content: string; className?: string };

export const RichText: React.FC<RichTextProps> = ({ content, className }) => {
    return <div className={classnames('rich-text', className)} dangerouslySetInnerHTML={toHTML(content)} />;
};
