import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useDeps } from 'src/hooks/useDeps';
import Bugsnag from '@bugsnag/js';

import { Container } from '../Layout/Container';
import { Wrapper } from '../Layout';
import footerMenuQuery from '../../queries/jhMenu.graphql';
import { jhMenu, jhMenuVariables } from '../../queries/__generated__/jhMenu';

import classes from './Footer.scss';
import { FooterCta } from './FooterCta';
import { FooterMenu, FooterMenus } from './FooterMenus';
import { FooterContact } from './FooterContact';

type FooterProps = Record<string, unknown>;

export const Footer: React.FC<FooterProps> = React.memo(() => {
    const { error, data, loading } = useQuery<jhMenu, jhMenuVariables>(footerMenuQuery, {
        variables: { identifier: 'wwe-footer-links' },
        ssr: useDeps().env.SSR_GQL,
    });

    if (error) {
        Bugsnag.notify(`Could not fetch Footer menus`, (event) => {
            event.addMetadata('errorObject', error);
        });
        return null;
    }
    if (loading) return null;
    if (!data || !Array.isArray(data.jhMenu)) {
        Bugsnag.notify('jhMenu was missing', (event) => {
            event.addMetadata('data', { data: data });
        });
        return null;
    }

    return (
        <Wrapper className={classes.footer} element="footer" bg="white">
            <Container variant="mobile-no-padding">
                <FooterCta />
                <FooterMenus menus={data.jhMenu as FooterMenu[]} />
                <FooterContact />
            </Container>
        </Wrapper>
    );
});
