import { useEffect, useRef } from 'react';
import { BehaviorSubject } from 'rxjs';

export function useBSubject<T extends any>(initial: T): BehaviorSubject<T> {
    const subject$ = useRef(new BehaviorSubject<T>(initial));

    useEffect(() => {
        const ref = subject$.current;
        return () => {
            if (ref) {
                ref.unsubscribe();
            }
        };
    }, [subject$]);

    return subject$.current;
}
