import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router/useSetResolved';

import { Container } from '../Layout/Container';
import { Wrapper } from '../Layout/Wrapper';
import { StoreState } from '../../types/global-types';
import { Button } from '../Button/Button';

type NotFoundProps = {
    message?: string;
};

const onlineSelector = (s: StoreState) => s.runtime.online;

export const ErrorComponent: React.FC<NotFoundProps> = () => {
    useSetResolved();
    const online = useSelector(onlineSelector, shallowEqual);
    const reload = useCallback(() => window.location.reload(), []);
    return (
        <Wrapper>
            <Container>
                <h1>Oops!</h1>
                <h3>There was a problem with that request</h3>
                {!online && <p>It may be because you're offline and haven't viewed this page before</p>}
                {!online && <p>Try reloading this page when you have internet access</p>}
                {!online && (
                    <p>
                        <Button onClick={reload}>Reload now</Button>
                    </p>
                )}
                {online && (
                    <div>
                        <p>This is normally solved by reloading the page.</p>
                        <p>
                            If the problem persists, contact us on <a href="tel:08000285867">0800 028 5867</a> or via
                            the live chat link at the bottom of the page.
                        </p>
                        <Button onClick={reload}>Reload now</Button>
                    </div>
                )}
            </Container>
        </Wrapper>
    );
};
