import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useHistoryLocationObservable } from 'src/hooks/router-hooks';
import { skip, subscribeOn, tap } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';
import { useBreakpointMatch, useRenderEnv } from 'src/hooks/useBreakpoint';
import { Delay } from 'src/components/Helpers/Delay';

import { contact } from '../../config.json';
import { Container } from '../Layout';
import { Svg } from '../Svg/Svg';
import { CurrencySelect } from '../StoreControls/CurrencySelect';
import { VatSelect } from '../StoreControls/VatSelect';
import { HeaderContext } from '../Header/Header';

import { NavQuery } from './NavList';
import classes from './Nav.scss';

export const Nav: React.FC = React.memo(() => {
    const { showNav, setShowNav } = useContext(HeaderContext);

    const outerRef = useRef<HTMLDivElement | null>(null);
    const { isServer, isBrowser } = useRenderEnv();
    const { isMob, isDesk } = useBreakpointMatch();
    const closeNav = useCallback(() => setShowNav(false), [setShowNav]);

    useClickOutside(outerRef, showNav, closeNav);

    const location$ = useHistoryLocationObservable();

    useEffect(() => {
        const sub = location$
            .pipe(
                skip(1),
                subscribeOn(asyncScheduler),
                tap(() => closeNav()),
            )
            .subscribe();
        return () => sub.unsubscribe();
    }, [closeNav, location$]);

    return (
        <nav
            data-scrolllock-nav
            ref={outerRef}
            className={classnames({
                [classes.nav]: true,
                [classes.navActive]: showNav,
            })}
        >
            <Container className={classes.navContainer}>
                <div className={classes.navHeader}>
                    <div className={classes.navHeaderItem}>
                        <CurrencySelect />
                    </div>
                    <div className={classes.navHeaderItem}>
                        <VatSelect />
                    </div>
                </div>
                <section className={classes.navHolder}>
                    {isServer && <NavQuery />}
                    {isMob && isBrowser && showNav && (
                        <Delay delay={300}>
                            <NavQuery />
                        </Delay>
                    )}
                    {isDesk && isBrowser && <NavQuery />}
                </section>
                <div className={classes.navFooter}>
                    <Link to="/order-tracking" className={classes.navFooterLink}>
                        <Svg name="aim" />
                        Track your order
                    </Link>
                    <a href={`tel:${contact.phone}`} className={classes.navFooterLink}>
                        <Svg name="phone" />
                        {contact.phone}
                    </a>
                    <Link to="/faqs" className={classes.navFooterLink}>
                        <Svg name="faq" />
                        FAQS
                    </Link>
                </div>
            </Container>
        </nav>
    );
});
