import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useDeps } from 'src/hooks/useDeps';
import { BannerSkeleton } from 'src/elements/Skeletons/Skeletons';
import { differenceInSeconds } from 'date-fns';
import Bugsnag from '@bugsnag/js';
import { StaticBanner } from 'src/components/Banner/StaticBanner';

import { adjustablePromoBanner } from '../../queries/__generated__/promoBanner';
import informationBarWidget from '../../queries/informationBarWidget.graphql';

import { Banner } from './Banner';

type BannerQueryProps = Record<string, unknown>;

const getTimerSeconds = (startDateString, endDateString) => {
    const startDiff = differenceInSeconds(new Date(), new Date(startDateString));
    const endDiff = differenceInSeconds(new Date(endDateString), new Date());

    if (startDiff > 0 && endDiff > 0) return endDiff;
};

export const BannerQuery: React.FC<BannerQueryProps> = () => {
    const { error, data, loading } = useQuery<adjustablePromoBanner>(informationBarWidget, {
        ssr: useDeps().env.SSR_GQL,
        fetchPolicy: 'no-cache',
    });

    if (error) return null;

    if (loading) return <BannerSkeleton />;

    if (!Array.isArray(data?.informationBarWidget)) {
        Bugsnag.notify('no data for promo banner', (event) => {
            event.addMetadata('data', { data: data });
        });
        return null;
    }

    const enabledBanners = data?.informationBarWidget.filter((banner) => Boolean(banner.widget_parameters.enabled));

    const enabledBannerWithMatchingTime = enabledBanners?.find((banner) =>
        Boolean(getTimerSeconds(banner.widget_parameters.starts_at, banner.widget_parameters.ends_at)),
    )?.widget_parameters;

    const enabledBannerWithoutTime = enabledBanners?.find(
        (banner) =>
            !banner.widget_parameters.starts_at &&
            !banner.widget_parameters.ends_at &&
            Boolean(banner.widget_parameters.enabled),
    )?.widget_parameters;

    const discountBanner = enabledBanners?.find(
        (banner) =>
            Boolean(getTimerSeconds(banner.widget_parameters.starts_at, banner.widget_parameters.ends_at)) &&
            banner.widget_parameters.discount_code !== null,
    )?.widget_parameters;

    const pickedBanner = discountBanner || enabledBannerWithMatchingTime || enabledBannerWithoutTime;

    return pickedBanner ? (
        <Banner
            title={pickedBanner.title}
            description={pickedBanner.description}
            timerSeconds={getTimerSeconds(pickedBanner.starts_at, pickedBanner.ends_at)}
            bgColour={pickedBanner.bg_color}
            fontColour={pickedBanner.font_color}
            link={pickedBanner.link}
            discountCode={pickedBanner.discount_code}
            url={pickedBanner.url}
        />
    ) : (
        <StaticBanner />
    );
};
