import React from 'react';

import { Messages } from '../Messages/Messages';
import { warningMessage } from '../../util/state-helpers';
import { Container } from '../Layout';

import classes from './AppShell.scss';

export const OfflineRibon: React.FC = () => {
    return (
        <Container className={classes.offlineRibon}>
            <Messages messages={[warningMessage('You are offline, some features are disabled.')]} />
        </Container>
    );
};
