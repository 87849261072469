import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistoryLocationObservable } from 'src/hooks/router-hooks';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import { debounceTime, skip, subscribeOn, tap } from 'rxjs/operators';
import { asyncScheduler, merge, Subject } from 'rxjs';
import { useQuery } from '@apollo/react-hooks';
import { jhMenu, jhMenuVariables } from 'src/queries/__generated__/jhMenu';
import { useDeps } from 'src/hooks/useDeps';
import { NavSkeleton } from 'src/elements/Skeletons/Skeletons';
import Bugsnag from '@bugsnag/js';
import classnames from 'classnames';

import navMenuQuery from '../../queries/jhMenu.graphql';
import { stripDomain } from '../../util/sanitizeLink';
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';

import { NavSub, NavSubMenu } from './NavSub';
import classes from './Nav.scss';

export interface NavMenu {
    title: string | null;
    url: string | null;
    type: string;
    html?: string | null;
    children: NavSubMenu[];
}

type NavListProps = {
    jhMenu: NavMenu[];
};

export const NavList: React.FC<NavListProps> = React.memo(({ jhMenu }) => {
    const [breakpoint] = useBreakpoint();
    const [openIndexes, toggleList] = useState(-1);
    const location$ = useHistoryLocationObservable();

    const hover$ = useConstant(() => new Subject<number>());
    const onMouseEnterOrLeave = useCallback((v: number) => hover$.next(v), [hover$]);

    useEffect(() => {
        const locationChanges$ = location$.pipe(
            skip(1),
            subscribeOn(asyncScheduler),
            tap(() => toggleList(-1)),
        );
        const hovers$ = hover$.pipe(
            debounceTime(200),
            tap((x) => toggleList(x)),
        );
        const sub = merge(locationChanges$, hovers$).subscribe();
        return () => sub.unsubscribe();
    }, [hover$, location$]);

    return (
        <ul className={classes.navList}>
            {jhMenu.map((item, index) => {
                const hasChildren = item.children.length > 0;
                const active = openIndexes === index;
                const itemUrl = stripDomain(item.url);
                return (
                    <li
                        key={item.title || `unknown-${index}`}
                        className={classnames(classes.navItem, {
                            [classes.black]: item.title?.includes('Clearance'),
                            ['black']: item.title?.includes('Clearance'),
                        })}
                        onMouseEnter={() => {
                            if (breakpoint === Breakpoint.Desk) {
                                onMouseEnterOrLeave(index);
                            }
                        }}
                        onMouseLeave={() => {
                            if (breakpoint === Breakpoint.Desk) {
                                onMouseEnterOrLeave(-1);
                            }
                        }}
                    >
                        <Link
                            to={itemUrl}
                            className={classes.navLink}
                            onClick={(e) => {
                                if (breakpoint === Breakpoint.Mob && hasChildren) {
                                    e.preventDefault();
                                    toggleList(index);
                                    const item = document.querySelector('[data-scrolllock-nav]');
                                    if (!item) return null;
                                    item.scrollTo(0, 0);
                                }
                            }}
                        >
                            {item.title}
                        </Link>
                        {hasChildren && (
                            <NavSub
                                subNavs={item.children}
                                parentUrl={itemUrl || ''}
                                parentTitle={item.title || 'unknown'}
                                showSub={active}
                                toggleList={(value) => toggleList(value)}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    );
});

export const NavQuery: React.FC = () => {
    const { error, data, loading } = useQuery<jhMenu, jhMenuVariables>(navMenuQuery, {
        variables: { identifier: 'wwe-main-menu' },
        ssr: useDeps().env.SSR_GQL,
    });

    if (error) return null;

    if (loading) return <NavSkeleton />;

    if (!data || !Array.isArray(data.jhMenu)) {
        Bugsnag.notify('jhMenu was missing', (event) => {
            event.addMetadata('data', { data: data });
        });
        return null;
    }

    return <NavList jhMenu={data?.jhMenu as NavMenu[]} />;
};
