import { EMPTY, of } from 'rxjs';
import { EpicDeps } from '@wearejh/m2-pwa-engine/lib/types';

import { CspMsg, STORAGE_KEY } from '../csp.register';

export function cspHydrateEpic(_action$, _state$, deps: EpicDeps) {
    const { storage } = deps;
    const prev = storage.get(STORAGE_KEY);

    if (prev) {
        return of(CspMsg('Csp.Hydrate', prev));
    }

    return EMPTY;
}
