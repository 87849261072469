import React, { useCallback, useState } from 'react';
import { Form } from '@wearejh/rx-form/lib/RxForm';
import { Fields } from '@wearejh/m2-pwa-user/lib/user.types';
import { basicEmailValidation } from 'src/util/forms';
import { useMutation } from '@apollo/react-hooks';
import generateMagicLinkToken from 'src/queries/generateMagicLinkToken.graphql';
import {
    generateCustomerAccessToken,
    generateCustomerAccessTokenVariables,
} from 'src/queries/__generated__/generateCustomerAccessToken';
import { MessageType } from '@wearejh/m2-pwa-engine/lib/types';

import { ModalHeader } from '../Modal/ModalHeader';
import { ModalBody } from '../Modal/ModalBody';
import { Input } from '../Forms/Input';
import { Actions } from '../Forms/Actions';
import { ModalFooter } from '../Modal/ModalFooter';
import { Modal } from '../Modal/Modal';
import { Fieldset } from '../Forms/Fieldset';
import { Spinner } from '../Layout/Spinner';
import { SvgIconNames } from '../Svg/icons-types';
import { Button } from '../Button/Button';
import { Block } from '../Helpers/Block';
import { Messages } from '../Messages/Messages';

export const formatGraphqlError = (error: any) => {
    let newText = error.message;
    if (newText.includes('GraphQL error:')) {
        newText = newText.replace('GraphQL error: ', '');
    }
    return newText;
};

export type MagicLinkProps = {
    close();
    open?: boolean;
};

export const MagicLink: React.FC<MagicLinkProps> = (props) => {
    return (
        <Modal type="popup" isOpen={props.open} onDismiss={props.close}>
            <MagicLinkInner onDismiss={props.close} />
            <ModalFooter onDismiss={props.close} cancelText="Cancel" />
        </Modal>
    );
};

export default MagicLink;

type MagicLinkInnerProps = {
    onDismiss?();
};

type SetupTextsProps = {
    title?: string;
    subtitle?: string;
    body?: string;
    icon?: SvgIconNames;
};

const defaultMagicTitle = 'Magic passwordless link';
const defaultMagicIcon = 'openLock';

export const MagicLinkInner: React.FC<MagicLinkInnerProps> = (props) => {
    const { onDismiss } = props;
    const [modalTitle, setModalTitle] = useState('Magic passwordless link');
    const [modalIcon, setModalIcon] = useState<SvgIconNames | undefined>('openLock');
    const [modalSubtitle, setModalSubtitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [step, setStep] = useState('start');

    const setupTexts = (options: SetupTextsProps) => {
        const { title, subtitle, body, icon } = options;
        title && setModalTitle(title);
        subtitle && setModalSubtitle(subtitle);
        body && setModalBody(body);
        icon && setModalIcon(icon);
    };

    const [getMagicLinkToken, { loading }] = useMutation<
        generateCustomerAccessToken,
        generateCustomerAccessTokenVariables
    >(generateMagicLinkToken, {
        onCompleted(data) {
            const result = data.generateCustomerAccessToken?.customerNotified;
            if (result === true) {
                setupTexts({
                    title: 'Link sent!',
                    subtitle: ' ',
                    body: 'Passwordless link sent. Please click on link in your emails',
                    icon: 'openLock',
                });
                const path = window.location.pathname;
                const search = window.location.search;

                localStorage.setItem('magicFrom', path + search);
                setStep('completed');
            } else {
                setupTexts({
                    title: 'Oops',
                    subtitle: 'Something went wrong',
                    body: 'Email was not sent. Please try again',
                    icon: 'alert',
                });
                setStep('error');
            }
        },
        onError: (error) => {
            const errorMsg = formatGraphqlError(error);
            setupTexts({
                title: 'Email not sent',
                subtitle: ' ',
                body: errorMsg || 'Please try again',
                icon: 'alert',
            });
            setStep('error');
        },
    });

    const onSubmit = useCallback(
        async (values) => {
            await getMagicLinkToken({
                variables: {
                    email: values.email,
                },
            });
        },
        [getMagicLinkToken],
    );

    const renderBody = () => {
        switch (step) {
            case 'start':
                return (
                    <Form onSubmit={onSubmit} data-test-id="MagicLinkForm">
                        <Fieldset disabled={false}>
                            <Input
                                label="Email"
                                field={Fields.email}
                                id={`modal-${Fields.email}`}
                                validate={basicEmailValidation()}
                                required={true}
                            />
                            <Actions primaryText="Continue" />
                        </Fieldset>
                    </Form>
                );
            case 'completed':
                return (
                    <Block>
                        <Messages
                            messages={[
                                {
                                    type: MessageType.Success,
                                    text: modalBody || 'Success',
                                },
                            ]}
                        />
                    </Block>
                );
            case 'error':
                return (
                    <>
                        <Block>
                            <Messages
                                messages={[
                                    {
                                        type: MessageType.Error,
                                        text: modalBody + ' Please try again.' || 'We have an error',
                                    },
                                ]}
                            />
                        </Block>
                        <Button
                            onClick={() => {
                                setStep('start');
                                setupTexts({
                                    title: defaultMagicTitle,
                                    subtitle: ' ',
                                    body: '',
                                    icon: defaultMagicIcon,
                                });
                            }}
                        >
                            Try again
                        </Button>
                    </>
                );
        }
    };

    return (
        <>
            <ModalHeader
                title={modalTitle}
                icon={modalIcon}
                onDismiss={onDismiss && onDismiss}
                subTitle={modalSubtitle}
            />
            <ModalBody>{loading ? <Spinner /> : renderBody()}</ModalBody>
        </>
    );
};
