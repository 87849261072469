export enum Type {
    shopByGender = 'Shop By Gender',
    shopByStyle = 'Shop By Style',
    shopByMaterial = 'Shop By Material',
    shopByBrand = 'Shop By Brand',
    shopByType = 'Shop By Type',
    shopBySafetyRating = 'Shop By Safety Rating',
    shopByProduct = 'Shop By Product',
    allProducts = 'All Products',
    brands = 'Brands',
    brandsAZ = 'Brands A-Z',
    bundleDeals = 'Bundle Deals',
}

type AlphabetProps = {
    title: string;
    url: string;
    type: string;
}[];

type AlphabetObjects = {
    title: string;
    children: {
        title: string;
        url: string;
        type: string;
    }[];
}[];

export const alphabeticalStructure = (arr: AlphabetProps) => {
    const onlyLetters = [...new Set(arr.map((el) => everyWordUpperCase(el.title.slice(0, 1))))];
    const objects: AlphabetObjects = onlyLetters.map((el) => ({ title: el, children: [] }));

    arr.map((el) => {
        return objects.map((object) => {
            if (everyWordUpperCase(el.title.slice(0, 1)) === object.title) {
                return object.children.push({
                    title: el.title,
                    url: el.url,
                    type: el.type,
                });
            }
        });
    });

    return objects;
};

const everyWordUpperCase = (word: string) => {
    return word.replace(/\b\w/g, (l) => l.toUpperCase());
};
