import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { cspLogin } from 'src/components/CustomerServicePortal/feature/epics/cspLogin.epic';
import { errorMessage } from 'src/util/state-helpers';
import { ActionMap, createMsg } from 'action-typed';
import { cspHydrateEpic } from 'src/components/CustomerServicePortal/feature/epics/cspHydrate.epic';
import { cspPersistUserEpic } from 'src/components/CustomerServicePortal/feature/epics/cspPersist.epic';
import { cpsSignOut } from 'src/components/CustomerServicePortal/feature/epics/cspSignOut.epic';

export const NAME = 'csp';
export const STORAGE_KEY = 'store.csp';

interface CustomerServiceRep {
    token: string;
    user: {
        first_name: string;
        last_name: string;
        email: string;
    };
}

export enum CspSession {
    SignedIn = 'SignedIn',
    SignedOut = 'SignedOut',
}

const initialCustomerServiceRep = {
    token: '',
    user: {
        first_name: '',
        last_name: '',
        email: '',
    },
};

export type Messages = {
    ['Csp.Hydrate']: {
        session: CspSession;
        token: string;
        user: CustomerServiceRep;
    };
    ['Csp.Persist']: undefined;
    ['Csp.Submit']: { username: string; password: string };
    ['Csp.SignOut']: undefined;
    ['Csp.Success']: CustomerServiceRep;
    ['Csp.Error']: string;
};

export type CspState = {
    status: DataStatus;
    session: CspSession;
    messages: Message[];
    customerServiceRep: CustomerServiceRep;
};

const initialState = {
    status: DataStatus.Idle,
    session: CspSession.SignedOut,
    messages: [],
    customerServiceRep: initialCustomerServiceRep,
};

export function cspRegister() {
    return {
        epics: [cspLogin, cspHydrateEpic, cspPersistUserEpic, cpsSignOut],
        reducers: {
            [NAME]: function (state = initialState, action: CspActions): CspState {
                switch (action.type) {
                    case 'Csp.Hydrate': {
                        return {
                            ...state,
                            ...action.payload,
                        };
                    }
                    case 'Csp.Submit': {
                        return {
                            ...state,
                            status: DataStatus.Pending,
                            session: CspSession.SignedOut,
                            messages: [],
                            customerServiceRep: initialCustomerServiceRep,
                        };
                    }
                    case 'Csp.Success': {
                        return {
                            ...state,
                            status: DataStatus.Success,
                            session: CspSession.SignedIn,
                            messages: [],
                            customerServiceRep: action.payload,
                        };
                    }
                    case 'Csp.Error': {
                        return {
                            ...state,
                            status: DataStatus.Error,
                            session: CspSession.SignedOut,
                            messages: [errorMessage(action.payload)],
                            customerServiceRep: initialCustomerServiceRep,
                        };
                    }
                    case 'Csp.SignOut': {
                        return {
                            ...initialState,
                        };
                    }
                    default:
                        return state;
                }
            },
        },
        name: NAME,
    } as any;
}

export const CspMsg = createMsg<Messages>();
export type TypeMap = ActionMap<Messages>;
export type CspActions = TypeMap[keyof TypeMap];
export type AppendCSPState<T = Record<string, unknown>> = T & { csp: CspState };
