import { ignoreElements, tap, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';

import { Actions, TypeMap } from '../app.actions';
import { Deps } from '../../../types/global-types';

/**
 * Handle hiding alerts to the user
 * @param action$
 * @param _state$
 * @param deps
 */
export function externalLink(action$: Observable<any>, _state$, deps: Deps): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['App.ExternalLink']>('App.ExternalLink'),
        withLatestFrom(deps.window$),
        tap(([{ payload }, window]) => {
            window.open(payload.url, '_blank');
        }),
        ignoreElements(),
    );
}
