import { useEffect, useRef } from 'react';
import { createDebug } from 'src/util/debug';

import { GtmEvents } from '../utils/eventTypes';
import { hasGtm } from '../gtm.types';

const debug = createDebug('useGtm');

export function fireGtm(event: GtmEvents) {
    if (!hasGtm(window)) {
        // eslint-disable-next-line no-console
        return console.log('no window.dataLayer, doing nothing with', event);
    }
    debug('pushing to dataLayer', event);
    (window as any).dataLayer.push(event);
    debug('next dataLayer', (window as any).dataLayer);
}

export function useOnceGtm(event: GtmEvents) {
    const seen = useRef(false);
    return useEffect(() => {
        if (!seen.current) {
            fireGtm(event);
            seen.current = true;
        }
    }, [event]);
}
