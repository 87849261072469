import React, { useContext } from 'react';
import classnames from 'classnames';

import { Svg } from '../Svg/Svg';

import classes from './HeaderControls.scss';
import { BasketIcon } from './BasketIcon';
import { AccountIcon } from './AccountIcon';
import { HeaderContext } from './Header';

type HeaderControlsProps = Record<string, unknown>;

export const HeaderControls: React.FC<HeaderControlsProps> = React.memo(() => {
    const { showNav, setShowNav, showSearch, setShowSearch } = useContext(HeaderContext);
    return (
        <div className={classes.headerControls}>
            <button
                type="button"
                className={classnames(
                    'headerControl',
                    [classes.headerControl],
                    [classes.headerControlMenu],
                    ['button--reset'],
                    {
                        [classes.headerControlMenuActive]: showNav,
                    },
                )}
                onClick={() => {
                    setShowNav(showNav ? false : true);
                }}
            >
                {!showNav && (
                    <>
                        <Svg name="menu" /> <span className={classes.headerControlText}>Menu</span>
                    </>
                )}
                {showNav && (
                    <>
                        <Svg name="close" /> <span className={classes.headerControlText}>Close</span>
                    </>
                )}
            </button>
            <button
                type="button"
                className={classnames(
                    'headerControl',
                    [classes.headerControl],
                    [classes.headerControlSearch],
                    ['button--reset'],
                )}
                onClick={() => {
                    setShowSearch(showSearch ? false : true);
                }}
            >
                {!showSearch && (
                    <>
                        <Svg name="search" /> <span className={classes.headerControlText}>Search</span>
                    </>
                )}
                {showSearch && (
                    <>
                        <Svg name="close" /> <span className={classes.headerControlText}>Close</span>
                    </>
                )}
            </button>
            <AccountIcon />
            <BasketIcon />
        </div>
    );
});
