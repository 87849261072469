import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { usePathname } from 'src/hooks/router-hooks';
import { useDispatch } from 'react-redux';
import { useDeps } from 'src/hooks/useDeps';
import { Svg } from 'src/components/Svg/Svg';

import { useMagicLink } from '../../hooks/useMagicLink';

import classes from './HeaderControls.scss';
import { HeaderContext } from './Header';

export const AccountIcon = () => {
    const { components, dispatch } = useMagicLink();
    const { isSignedIn } = useContext(HeaderContext);

    return (
        <>
            {!isSignedIn && <SignedOut dispatch={dispatch} />}
            {isSignedIn && (
                <Link
                    className={classnames(classes.headerControl, classes.headerControlUser, 'headerControl')}
                    to="/customer/account"
                >
                    <img
                        className={classes.headerControlImageDesktop}
                        src={require('../../../static/icons/login-register.svg')}
                        alt="my account icon"
                    />
                    <Svg
                        className={classnames(classes.headerControlImageMobile, 'headerControlImageMobile')}
                        name="my-account"
                    />
                    <span className={classes.headerControlText}>Account</span>
                    <span className={classes.headerControlContent}>My Account</span>
                </Link>
            )}
            {components}
        </>
    );
};

/**
 * This is extracted so that we can change functionality
 * based on which page the user is on.
 *
 * If the user is on ANY customer page, we will NOT show the modals, ever
 *
 */
const SignedOut: React.FC<{ dispatch: (evt: { kind: 'sign-in' }) => void }> = (props) => {
    const { dispatch } = props;
    const pathname = usePathname();
    const reduxDispatch = useDispatch();
    const { push, paths } = useDeps();
    const onClick = useCallback(() => {
        if (pathname.indexOf('/customer/') === 0) {
            reduxDispatch(push(paths.customer.login));
        } else {
            dispatch({ kind: 'sign-in' });
        }
    }, [dispatch, pathname, paths, reduxDispatch, push]);
    return (
        <button
            className={classnames(
                classes.headerControl,
                classes.headerControlUser,
                classes.headerControAccount,
                'headerControl',
            )}
            type="button"
            data-test-id="AccountIcon_login"
            onClick={onClick}
        >
            <img
                className={classes.headerControlImageDesktop}
                src={require('../../../static/icons/login-register.svg')}
                alt="my account icon"
            />
            <Svg
                className={classnames(classes.headerControlImageMobile, 'headerControlImageMobile')}
                name="my-account"
            />
            <span className={classes.headerControlText}>Account</span>
            <span className={classes.headerControlContent}>Login / Register</span>
        </button>
    );
};
