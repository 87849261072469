import React from 'react';
import { Container } from 'src/components/Layout/Container';
import { Link } from 'react-router-dom';
import { AccountIcon } from 'src/components/Header/AccountIcon';
import { OrderTrackingIcon } from 'src/components/Header/OrderTrackingIcon';

import classes from './SubHeader.scss';

export const SubHeader: React.FC = () => (
    <div className={classes.wrapper}>
        <Container className={classes.container}>
            <OrderTrackingIcon />
            <Link to="/contact-us">
                <img src={require('../../../static/icons/need-help.svg')} alt="need help icon" />
                <span>Need Help</span>
            </Link>
            <Link to="/bulk-and-corporate">
                <img src={require('../../../static/icons/bulk-corporate.svg')} alt="bulk and corporate icon" />
                <span>Bulk & Corporate Users</span>
            </Link>
            <AccountIcon />
        </Container>
    </div>
);
