import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

import classes from './TwoCol.scss';

type TwoColProps = {
    left?: React.ReactNode;
    right?: React.ReactNode;
    main: React.ReactNode;
    wide?: boolean;
} & HTMLAttributes<any>;

export const TwoCol: React.FC<TwoColProps> = ({ left, right, main, wide = false, ...rest }) => (
    <section
        className={classnames(classes.root, {
            [classes.wide]: wide,
        })}
        {...rest}
    >
        {left && <div className={classes.left}>{left}</div>}
        <div
            className={classnames(classes.main, {
                [classes.hasLeft]: left,
                [classes.hasRight]: right,
            })}
        >
            {main}
        </div>
        {right && <div className={classes.right}>{right}</div>}
    </section>
);
