import React from 'react';

import { Field } from './Field';

type Props = {
    disabled?: boolean;
    className?: string;
};

export const Fieldset: React.FC<Props> = (props) => {
    const { disabled, children, className } = props;
    return (
        <fieldset disabled={disabled} className={className || ''}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    if (child.props && typeof child.props.field === 'string') {
                        return <Field>{child}</Field>;
                    }
                }
                return child;
            })}
        </fieldset>
    );
};
