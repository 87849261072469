import { replaceUrlParams } from './replaceUrlParams';
import { createDebug } from './debug';

const debug = createDebug('apiUrl');

export function apiUrl(operationId: string) {
    debug(operationId);
    return _apiUrl;
}

export function _apiUrl(path: string, params = {}, fields?: string[]): string {
    const joinedPath = ['/rest', replaceUrlParams(path, params)].join('');
    if (fields && fields.length > 0) {
        return [joinedPath, fields.join(',')].join('?fields=');
    }
    return joinedPath;
}
