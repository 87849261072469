import Axios from 'axios';

export const sendFbConversionEvent = async (data) => {
    const testEvent =
        data.data[0].event_source_url.includes('localhost') || data.data[0].event_source_url.includes('staging');

    const testUrl =
        'https://graph.facebook.com/v10.0/362950845855647/events?access_token=EAAE9OnQmGZCgBAJWHDaWQUxFKL0Hriq1R6zZCAuSZCew0fssqCeMemiY5ZAEBAUgSlYuYaYoDwZADiRzqe11ydJy4e15pZA2kf3ADmx21ZA5waEr6ioc5eUwZCpuHTsHfKlzs8EUdPdZBcvkV8PsRAOXTGeDID5YkacZCOgMgmz2ZB0ZAVdUIG7Ry4IVtGy5uegwaagZD';
    const prodUrl =
        'https://graph.facebook.com/v10.0/533751407052024/events?access_token=EAAE9OnQmGZCgBAAb8VzmPOsv5kIzZCDSNK1ZAGAvBfUlKKawD9OhPoXkyzSzNyF1uLNvxz7JmQUTv1OOPLlcWRC0xCASQriDvtsR3f3Ce1hS1cvGiOfNOA4rhl5uuk0xp2LKCZCImx7OwCZByBtlzKwS8G7QiWcCB90nRlKWkicqLG3WqrLqAhrXvBsqgteAZD';
    const url = testEvent ? testUrl : prodUrl;

    const headers = {
        Accept: 'application/json',
    };

    Axios({
        method: 'POST',
        url: url,
        headers,
        data,
    });
};
