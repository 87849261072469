import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import classes from './HeaderControls.scss';

export const OrderTrackingIcon: React.FC = () => (
    <Link to="/order-tracking">
        <img src={require('../../../static/icons/aim-checkout.svg')} alt="aim icon" />
        <span>Track order</span>
    </Link>
);
