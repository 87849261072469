import React, { useCallback, useEffect, useState } from 'react';
import { useCustomerStatus } from '@wearejh/m2-pwa-user/lib/hooks/useCustomerStatus';
import { useItemCount } from '@wearejh/m2-pwa-cart-gql/lib/hooks/useItemCount';
import { useSignoutCallback } from '@wearejh/m2-pwa-user/lib/hooks/useSignOut';
import { of } from 'rxjs';
import { usePathnameObservable, useSignedInObservable } from 'src/hooks/router-hooks';
import { SearchLoader } from 'src/components/Algolia/SearchDropdown/SearchLoader';
import { useDeps } from 'src/hooks/useDeps';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Breakpoint, useBreakpoint } from 'src/hooks/useBreakpoint';
import classnames from 'classnames';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { Nav } from 'src/components/Nav/Nav';
import { useCspIsLoggedIn } from 'src/components/CustomerServicePortal/hooks/useCspLogin';
import { CartState } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import { CartItemType } from 'src/util/enums';
import { useHeaderColor } from 'src/components/Header/hooks/useHeaderColor';
import Bugsnag from '@bugsnag/js';
import { SubHeader } from 'src/components/Header/SubHeader';
import { HeaderCallUs } from 'src/components/Header/HeaderCallUs';

import { Container, Wrapper } from '../Layout';

import classes from './Header.scss';
import { HeaderControls } from './HeaderControls';
import { HeaderLogo } from './HeaderLogo';
import { HeaderStore } from './HeaderStore';

type HeaderProps = {
    isOpen: boolean;
};

export const HeaderContext = React.createContext({
    isSignedIn: false,
    showNav: false,
    showSearch: false,
    itemCount: 0,
    cartPending: false,
    pathname$: of(''),
    signedIn$: of(false),
    goToAccount: () => {
        Bugsnag.notify('goToAccount not implemented');
    },
    setShowNav: (..._args: any[]) => {
        Bugsnag.notify('unable to open Navigation');
    },
    setShowSearch: (..._args: any[]) => {
        Bugsnag.notify('unable to open Search');
    },
    logout: (..._args: any[]) => {
        Bugsnag.notify('unable to open logout');
    },
    isCsRSignedIn: false,
});

export const Header: React.FC<HeaderProps> = React.memo(({ isOpen, children }) => {
    const backgroundColor = useHeaderColor();

    return (
        <>
            <SubHeader />
            <Wrapper
                element="header"
                bg={backgroundColor}
                data-test-id="DefaultHeader"
                className={classnames(classes.header, {
                    [classes.headerWithOverlay]: isOpen,
                })}
            >
                <Container className={classes.headerContainer}>
                    <div className={classes.headerLeft}>
                        <HeaderLogo />
                        <SearchLoader />
                        <HeaderCallUs />
                    </div>
                    <div className={classes.headerRight}>
                        <HeaderStore bg={backgroundColor} />
                        <HeaderControls />
                    </div>
                </Container>
                {children}
            </Wrapper>
            {isOpen && <div className={classes.overlay}> </div>}
        </>
    );
});

const stateSelector = (x: { cart: CartState }) =>
    x.cart.items.filter((item) => String(item.__typename) !== CartItemType.VirtualCartItem).length;

export const HeaderProvider: React.FC = React.memo(() => {
    const itemCount: number = useSelector(stateSelector, shallowEqual);
    const [showNav, setShowNav] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const { isSignedIn } = useCustomerStatus();
    const { status } = useItemCount();
    const pathname$ = usePathnameObservable();
    const signedIn$ = useSignedInObservable();
    const logout = useSignoutCallback();
    const { paths, replace } = useDeps();
    const dispatch = useDispatch();
    const goToAccount = useCallback(() => {
        dispatch(replace(paths.customer.account));
    }, [dispatch, paths, replace]);
    const [breakpoint] = useBreakpoint();
    const isCsRSignedIn = useCspIsLoggedIn();

    useEffect(() => {
        const $scrollableSearch = document.querySelector(`[data-scrolllock-search]`);
        const $scrollableNav = document.querySelector(`[data-scrolllock-nav]`);

        if (breakpoint === Breakpoint.Desk) return;

        if (showSearch) {
            disablePageScroll($scrollableSearch);
        }
        if (showNav) {
            disablePageScroll($scrollableNav);
        }
        return () => {
            enablePageScroll($scrollableNav);
            enablePageScroll($scrollableSearch);
        };
    }, [breakpoint, showNav, showSearch]);

    return (
        <HeaderContext.Provider
            value={{
                showNav: showNav,
                setShowNav,
                showSearch: showSearch,
                setShowSearch,
                isSignedIn,
                itemCount: itemCount || 0,
                cartPending: status === DataStatus.Pending,
                pathname$,
                signedIn$,
                logout,
                goToAccount,
                isCsRSignedIn: isCsRSignedIn || false,
            }}
        >
            <Header isOpen={breakpoint === Breakpoint.Desk ? false : showNav || showSearch}>
                <Nav />
            </Header>
        </HeaderContext.Provider>
    );
});
