import React, { HTMLAttributes } from 'react';

import classes from './SkeletonBlock.scss';

type SkeletonBlockProps = {
    className?: string;
} & HTMLAttributes<any>;

export const SkeletonBlock: React.FC<SkeletonBlockProps> = (props) => {
    const { className = '', ...restProps } = props;
    return <div className={`${classes.skeletonBlock} ${className}`} {...restProps} />;
};
