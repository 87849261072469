import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
    enabled: boolean;
    apiKey: string | null;
};

export const LiveChat: React.FC<Props> = (props) => {
    const [src, setSrc] = useState<string | null>(null);
    const { apiKey } = props;
    useEffect(() => {
        (window as any).__lc = (window as any).__lc || {};
        (window as any).__lc.license = apiKey;
        setSrc(`https://cdn.livechatinc.com/tracking.js`);
    }, [apiKey]);
    if (!src) return null;
    return (
        <Helmet>
            <script type="text/javascript" src={src} async={true} />
        </Helmet>
    );
};
