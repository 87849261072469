import { useTreatments } from '@splitsoftware/splitio-react';

export const useHeaderColor = () => {
    const featureName = 'Black_Header';
    const treatments = useTreatments([featureName]);
    const { treatment } = treatments[featureName];

    const backgroundColor = treatment === 'on' ? 'alternateBlack' : 'orange';

    return backgroundColor;
};
