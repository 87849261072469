import React from 'react';
import { useNextTick } from 'src/hooks/useNextTick';
import { useCspIsLoggedIn } from 'src/components/CustomerServicePortal/hooks/useCspLogin';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { cspCustomerSignIn } from 'src/components/CustomerServicePortal/feature/cspCustomerSignin.register';
import { Spinner } from 'src/components/Layout/Spinner';

import classes from './CspToolbar.scss';

const LazyCspToolbarConnected = React.lazy(
    () => import(/* webpackChunkName:"lazy_csp_header" */ './CspToolbarConnected'),
);

export function CspHeader() {
    const ready = useNextTick();
    if (!ready) return <div />;
    return <CspHeaderLoader />;
}

export function CspHeaderLoader() {
    const ready = useLazyRegister(cspCustomerSignIn, true, 'CspFormConnected');
    const isCsRSignedIn = useCspIsLoggedIn();
    if (!isCsRSignedIn) return null;
    if (!ready)
        return (
            <div>
                <Spinner />
            </div>
        );
    return (
        <React.Suspense fallback={<div className={classes.wrapper} />}>
            <LazyCspToolbarConnected />
        </React.Suspense>
    );
}
