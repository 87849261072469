import React, { useContext, useEffect, useReducer } from 'react';
import { SignInModalProps } from 'src/components/SignIn/SignInModal';
import { RegisterModalProps } from 'src/components/SignIn/RegisterModal';
import { ForgotModalProps } from 'src/components/SignIn/ForgotModal';
import { LazyLoader } from 'src/components/Helpers/LazyLoader';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import { BehaviorSubject, merge } from 'rxjs';
import { distinctUntilChanged, filter, skip, withLatestFrom } from 'rxjs/operators';
import { HeaderContext } from 'src/components/Header/Header';
import { useLocation } from 'react-router-dom';

import { MagicLinkProps } from '../SignIn/MagicLink';

enum Value {
    SignIn = 'SignIn',
    Forgot = 'Forgot',
    None = 'None',
    Register = 'Register',
    MagicLink = 'MagicLink',
    MagicLinkError = 'MagicLinkError',
    MagicLinkConfirmation = 'MagicLinkConfirmation',
}

type State = {
    value: Value;
};

export type Events =
    | {
          kind: 'sign-in';
      }
    | {
          kind: 'register';
      }
    | {
          kind: 'close';
      }
    | {
          kind: 'logout';
      }
    | {
          kind: 'forgot';
      }
    | {
          kind: 'magicLink';
      }
    | {
          kind: 'magicLinkError';
      }
    | {
          kind: 'magicLinkConfirmation';
      };

function accountReducer(state: State, action: Events): State {
    switch (action.kind) {
        case 'sign-in': {
            return { ...state, value: Value.SignIn };
        }
        case 'register': {
            return { ...state, value: Value.Register };
        }
        case 'close': {
            return { ...state, value: Value.None };
        }
        case 'logout': {
            return { ...state, value: Value.None };
        }
        case 'forgot': {
            return { ...state, value: Value.Forgot };
        }
        case 'magicLink': {
            return { ...state, value: Value.MagicLink };
        }
        case 'magicLinkError': {
            return { ...state, value: Value.MagicLinkError };
        }
        case 'magicLinkConfirmation': {
            return { ...state, value: Value.MagicLinkConfirmation };
        }
        default:
            return state;
    }
}

type useLazyModalsProps = {
    errorBody?: string;
};

export function useLazyModals(options?: useLazyModalsProps) {
    const { pathname$, signedIn$, goToAccount } = useContext(HeaderContext);
    const [{ value }, dispatch] = useReducer(accountReducer, { value: Value.None });
    const value$ = useConstant(() => new BehaviorSubject<Value>(value));
    const location = useLocation();

    useEffect(() => value$.next(value), [value, value$]);

    useEffect(() => {
        const pathchanges$ = pathname$.pipe(skip(1), distinctUntilChanged());
        const statusChanges$ = signedIn$.pipe(
            skip(1),
            filter((x) => Boolean(x)),
        );
        const sub = merge(pathchanges$, statusChanges$)
            .pipe(withLatestFrom(value$))
            .subscribe(([, value]) => {
                switch (value) {
                    case Value.Register: {
                        goToAccount();
                    }
                }
                dispatch({ kind: 'close' });
            });
        return () => sub.unsubscribe();
    }, [value$, pathname$, signedIn$, goToAccount]);

    useEffect(() => {
        dispatch({ kind: 'close' });
    }, [location]);

    const components = (
        <>
            {value === Value.SignIn && (
                <LazyLoader
                    fallback={null}
                    loader={
                        <LazySignIn
                            close={() => dispatch({ kind: 'close' })}
                            forgot={() => dispatch({ kind: 'forgot' })}
                            register={() => dispatch({ kind: 'register' })}
                            magicLink={() => dispatch({ kind: 'magicLink' })}
                        />
                    }
                />
            )}
            {value === Value.Register && (
                <LazyLoader
                    fallback={null}
                    loader={
                        <LazyRegister
                            close={() => dispatch({ kind: 'close' })}
                            login={() => dispatch({ kind: 'sign-in' })}
                            forgot={() => dispatch({ kind: 'forgot' })}
                        />
                    }
                />
            )}
            {value === Value.Forgot && (
                <LazyLoader fallback={null} loader={<LazyForgot close={() => dispatch({ kind: 'close' })} />} />
            )}
            {value === Value.MagicLink && (
                <LazyLoader
                    fallback={null}
                    loader={<LazyMagicLink close={() => dispatch({ kind: 'close' })} open={true} />}
                />
            )}
            {value === Value.MagicLinkError && (
                <LazyLoader
                    fallback={null}
                    loader={
                        <LazyMagicLinkErrorPopup
                            close={() => dispatch({ kind: 'close' })}
                            open={true}
                            errorBody={options?.errorBody}
                            goToMagicLink={() => dispatch({ kind: 'magicLink' })}
                        />
                    }
                />
            )}
            {value === Value.MagicLinkConfirmation && (
                <LazyLoader
                    fallback={null}
                    loader={<LazyMagicLinkConfirmationPopup close={() => dispatch({ kind: 'close' })} />}
                />
            )}
        </>
    );

    return { components, dispatch };
}

const LazySignIn: React.FC<SignInModalProps> = React.lazy(
    () => import(/* webpackChunkName:"__lazy__signin" */ '../SignIn/SignInModal'),
);

const LazyRegister: React.FC<RegisterModalProps> = React.lazy(
    () => import(/* webpackChunkName:"__lazy__register" */ '../SignIn/RegisterModal'),
);

const LazyForgot: React.FC<ForgotModalProps> = React.lazy(
    () => import(/* webpackChunkName:"__lazy__forgot" */ '../SignIn/ForgotModal'),
);

const LazyMagicLink: React.FC<MagicLinkProps> = React.lazy(
    () => import(/* webpackChunkName:"__lazy__magicLink" */ '../SignIn/MagicLink'),
);

const LazyMagicLinkErrorPopup: React.FC<any> = React.lazy(
    () => import(/* webpackChunkName:"__lazy__magicLinkErrorPopup" */ './MagicLinkErrorPopup'),
);

const LazyMagicLinkConfirmationPopup: React.FC<any> = React.lazy(
    () => import(/* webpackChunkName:"__lazy__magicLinkConfirmationPopup" */ './MagicLinkConfirmationPopup'),
);
