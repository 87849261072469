import { Observable } from 'rxjs';
import * as Definitions from 'swagger/ts/Definitions';
import { Effects } from 'swagger/index';

export const description = 'Create access token for admin given the customer credentials.';
export const method = 'POST';
export const operationId = 'integrationCustomerTokenServiceV1CreateCustomerAccessTokenPost';
export const path = '/V1/integration/customer/token';
export interface Body {
    username: string;
}
export type Response = Response200 | Response400 | ResponseDefault;
export type Response200 = string;
export type Response400 = Definitions.ErrorResponse;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, 'apiUrl' | 'restHeaders' | 'postJSON'>;
export function execute(body: Body, effects: MinimalEffects): Observable<Response200> {
    return effects.postJSON(effects.apiUrl(operationId)(path, undefined), body, effects.restHeaders({})) as any;
}
