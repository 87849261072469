import React from 'react';
import Smartlook from 'smartlook-client';
import environments from 'src/env';
import { readJson } from '@wearejh/m2-pwa-engine/lib/utils/readJson';
import { APP_ENV_STATE_ID } from '@wearejh/m2-pwa-engine/lib/browser/initBrowserApollo';

const appEnv = readJson(APP_ENV_STATE_ID);

export const SmartlookBoundary = ({ children }) => {
    const isMaster = appEnv.RELEASE_STAGE === 'production';
    const key = environments[isMaster ? 'master' : appEnv.RELEASE_STAGE].SMARTLOOK_ID;

    key && Smartlook.init('0c0c279e5220c9afc1da2cde73569c03b5e9da31');

    return <div>{children}</div>;
};
