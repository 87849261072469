import { concat, defer, merge, Observable, of } from 'rxjs';
import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { execute } from 'swagger/ts/CustomerCustomerRepositoryV1GetByIdGet2';
import { ofType } from 'redux-observable';
import { catchable } from '@wearejh/swagger-rxjs/utils/ajax-helpers';
import { UserMsg, UserActions } from '@wearejh/m2-pwa-user/lib/user.actions';
import { Deps } from 'src/types/global-types';

import { CspActions, TypeMap } from '../cspCustomerSignin.register';
import { cspCustomerSignIn } from '../../utils/cspCustomerSignIn';

export function cspCustomerSignInEpic(action$: Observable<any>, state$: Observable<any>, deps: Deps): Observable<any> {
    return action$.pipe(
        ofType<CspActions, TypeMap['CspUser.SignIn']>('CspUser.SignIn'),
        switchMap(({ payload }) => {
            const ajaxPayload = {
                username: payload.emailAddress,
                password: ' ',
            };

            const blockers$ = merge(
                action$.pipe(ofType<UserActions>('User.Reset')),
                action$.pipe(ofType(deps.LOCATION_CHANGE)),
            );

            return cspCustomerSignIn(ajaxPayload, payload.cspToken, deps)
                .pipe(
                    switchMap((token: string) => {
                        return concat(
                            of(UserMsg('User.SignInSetToken', { token })),
                            defer(() =>
                                execute(deps).pipe(
                                    mergeMap((info) => {
                                        return of(UserMsg('User.SignInSuccess', info), UserMsg('User.Persist'));
                                    }),
                                ),
                            ),
                        );
                    }),
                    takeUntil(blockers$),
                )
                .pipe(
                    catchable((e) => {
                        return of(UserMsg('User.SignInError', e.error));
                    }),
                );
        }),
    );
}
