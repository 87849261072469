import { imagePath } from 'src/util/formatUrl';
import { ProductPrice, ConfigurableOptions } from 'src/types/global-types';
import { TierPrice } from 'src/components/ProductDetail/TieredPricing';

import { categoryProducts_categoryProducts_items } from '../../../queries/__generated__/categoryProducts';

export interface ProductListItem {
    id: number;
    sku: string;
    name: string;
    url: string;
    url_key?: string;
    is_24_hour_delivery: boolean | null;
    number_of_colors_available: number | null;
    can_customise: boolean;
    is_bestseller: boolean;
    price: ProductPrice;
    margin_tier_prices?: TierPrice[];
    small_image: {
        label: string;
        url: string;
    };
    product_tags: string[];
    brand: string;
    categories?: {
        id: number;
    }[];
    configurable_options?: ConfigurableOptions[];
}

export const getLowestPrice = (
    item: categoryProducts_categoryProducts_items | any,
    type: keyof ProductPrice,
    checkBundle?: boolean,
) => {
    if (!checkBundle && item && item.margin_tier_prices.length === 0) return null;
    if (item === null) return null;

    return (
        [...item.margin_tier_prices].sort((a, b) => a![type] - b![type])[0]?.[type] ||
        parseFloat(
            item.price_range.minimum_price[
                type === 'price' ? 'regular_price_excl_formatted' : 'regular_price_formatted'
            ],
        )
    );
};

export const getHighestPrice = (
    item: categoryProducts_categoryProducts_items | any,
    type: keyof ProductPrice,
    checkBundle?: boolean,
) => {
    if (!checkBundle && item && item.margin_tier_prices.length === 0) return null;
    if (item === null) return null;

    return (
        [...item.margin_tier_prices].sort((a, b) => b![type] - a![type])[0]?.[type] ||
        parseFloat(
            item.price_range.minimum_price[
                type === 'price' ? 'regular_price_excl_formatted' : 'regular_price_formatted'
            ],
        )
    );
};

const getUrl = (item: categoryProducts_categoryProducts_items | any, selectedColors) => {
    let colorId: number | undefined;
    let url: string = '/' + item.url_key!;

    if (!Boolean(selectedColors)) {
        return url;
    }

    const allSelectedColors = selectedColors?.split(',');

    item.configurable_options &&
        item.configurable_options[0].values.map((v) => {
            allSelectedColors.forEach((c) => {
                if (v.label.toLowerCase()?.includes(c.toLowerCase())) {
                    colorId = v.value_index;
                }
            });
        });

    url = colorId ? `/${item.url_key!}?color=${colorId}` : `/${item.url_key!}`;

    return url;
};

export const productListItemsFromQuery = (
    items: (categoryProducts_categoryProducts_items | null)[] | any,
    bestSellers: Set<number> | undefined,
    searchParams?: string,
) => {
    const selectedColours = new URLSearchParams(searchParams).get('colour_family');
    return items.filter(Boolean).map((item) => {
        if (!item) throw new Error("trying to process a list item that doesn't exist");
        const productPrice: ProductPrice = {
            regular_price_incl: 0,
            regular_price: 0,
            final_price_incl: 0,
            final_price: 0,
            price_incl: getLowestPrice(item, 'price_incl', true),
            price: getLowestPrice(item, 'price', true),
            price_highest: getHighestPrice(item, 'price', true),
            price_highest_incl: getHighestPrice(item, 'price_incl', true),
            previous_prices: item.previous_prices,
        };
        const url = getUrl(item, selectedColours);

        return {
            id: item.id!,
            sku: item.sku || '',
            name: item.name || 'unknown',
            url,
            is_24_hour_delivery: item.is_24_hour_delivery,
            can_customise: item.can_customise,
            small_image: {
                label: item.small_image?.label || 'unknown',
                url: imagePath(item.selection_image ? item.selection_image : item.small_image?.url),
            },
            price: productPrice,
            is_bestseller: bestSellers?.has(item.id!),
            number_of_colors_available: item.number_of_colors_available || 0,
            product_tags: item.product_tags === null ? [] : item.product_tags.split(','),
            brand: item.brand_badge.label || '',
            categories: item.categories,
            configurable_options: item.configurable_options,
        };
    });
};
