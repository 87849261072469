import React from 'react';
import classnames from 'classnames';
import { getBackgroundColorClassName } from 'src/components/Header/utils/Header';
import { NavBackgroundColor } from 'src/types/global-types';

import classes from './Wrapper.scss';

type WrapperProps = {
    className?: string;
    variant?: 'normal' | 'page-inner' | 'page-product';
    element?: 'div' | 'nav' | 'header' | 'footer' | 'section';
    id?: string;
    lighterBackground?: boolean;
    overflowHidden?: boolean;
} & NavBackgroundColor;

export const Wrapper: React.FC<WrapperProps> = ({
    bg,
    children,
    className,
    variant,
    element,
    lighterBackground = false,
    overflowHidden = false,
    ...restProps
}) => {
    const classesString = classnames(classes.root, className || '', {
        [classes.variantPageInner]: variant === 'page-inner',
        [classes.variantPageProduct]: variant === 'page-product',
        [classes.lighterBackground]: lighterBackground,
        [classes.overflowHidden]: overflowHidden,
        ...getBackgroundColorClassName(classes, bg),
    });

    if (element === 'nav') {
        return (
            <nav className={classesString} {...restProps}>
                {children}
            </nav>
        );
    }
    if (element === 'header') {
        return (
            <header className={classesString} {...restProps}>
                {children}
            </header>
        );
    }
    if (element === 'footer') {
        return (
            <footer className={classesString} {...restProps}>
                {children}
            </footer>
        );
    }

    return (
        <div className={classesString} {...restProps}>
            {children}
        </div>
    );
};

Wrapper.defaultProps = {
    variant: 'normal',
    element: 'div',
};
