import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useDeps } from 'src/hooks/useDeps';

import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint';
import { Svg } from '../Svg/Svg';
import { stripDomain } from '../../util/sanitizeLink';

import classes from './FooterMenus.scss';

export interface FooterMenu {
    title: string;
    children: {
        title: string;
        url: string;
    }[];
}

type FooterMenusProps = {
    menus: FooterMenu[];
};

export const FooterMenus: React.FC<FooterMenusProps> = React.memo(({ menus }) => {
    const { DOMAIN } = useDeps().env;
    const [breakpoint] = useBreakpoint();
    const [openIndexes, toggleList] = useState([] as number[]);

    return (
        <div className={classes.footerMenus}>
            {menus.map((menu, index) => {
                const active = openIndexes.indexOf(index) > -1;
                return (
                    <div key={menu.title} className={classes.footerMenusMenu}>
                        <h3
                            className={classnames(classes.footerMenusTitle, {
                                [classes.footerMenusTitleActive]: active,
                            })}
                            onClick={() => {
                                if (breakpoint === Breakpoint.Mob) {
                                    toggleList((children) => {
                                        if (children.indexOf(index) > -1) {
                                            return children.filter((x) => x !== index);
                                        }
                                        return children.concat(index);
                                    });
                                }
                            }}
                        >
                            {menu.title} <Svg name="arrow-down" />
                        </h3>
                        <ul
                            className={classnames({
                                [classes.footerMenusList]: true,
                                [classes.footerMenusListActive]: active,
                            })}
                        >
                            {menu.children.map((subchildren) => (
                                <li key={subchildren.title} className={classes.footerListsItem}>
                                    <FooterMenuLink url={subchildren.url} title={subchildren.title} domain={DOMAIN} />
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
});

type FooterMenuLinkProps = {
    url: string;
    title: string;
    domain: string;
};

const FooterMenuLink: React.FC<FooterMenuLinkProps> = (props) => {
    const { url, title, domain } = props;

    if (!url) return null;

    // has domain eg workwear and does not lead to media
    if (url.includes(domain) && !url.includes('/pub/media/')) {
        return (
            <Link to={stripDomain(url)} className={classes.footerListsLink}>
                {title}
            </Link>
        );
    }

    // is External
    if (url.includes('https://' || 'http://')) {
        return (
            <a href={url} className={classes.footerListsLink} target="_blank" rel="noreferrer">
                {title}
            </a>
        );
    }

    // has no domain
    return (
        <Link to={url} className={classes.footerListsLink}>
            {title}
        </Link>
    );
};
