import React from 'react';
import classnames from 'classnames';
import { useTreatments } from '@splitsoftware/splitio-react';

import classes from './StaticBanner.scss';

export const StaticBanner = () => {
    const featureName = 'Red_Top_Banner';
    const treatments = useTreatments([featureName]);
    const { treatment } = treatments[featureName];

    return (
        <strong
            className={classnames(classes.staticBanner, {
                [classes.red]: treatment === 'on',
            })}
        >
            <div>
                <img src={require('../../../static/icons/guarantee.svg')} alt="guarantee icon" />
                <span>UK’s number one since 1990</span>
            </div>
            <div>
                <img src={require('../../../static/icons/free-delivery.svg')} alt="free delivery icon" />
                <span>Get free delivery on orders over £99 net!</span>
            </div>
        </strong>
    );
};
