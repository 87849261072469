import qs from 'qs';

import { SortEnum } from '../../../../__generated__/globalTypes';

export type UrlParams = {
    sortName?: SortEnum;
    sortPrice?: SortEnum;
    currentPage?: number;
    tag?: 'tag';
};

export function deserializeUrl(search: string): UrlParams {
    const params = search.substr(1);
    const obj = qs.parse(params);
    const output: UrlParams = {};

    if (obj.order === 'name') {
        if (obj.dir === SortEnum.ASC || obj.dir === SortEnum.DESC) {
            output.sortName = obj.dir;
        }
    }

    if (obj.order === 'price') {
        if (obj.dir === SortEnum.ASC || obj.dir === SortEnum.DESC) {
            output.sortPrice = obj.dir;
        }
    }

    if (obj.page) {
        if (obj.page !== '') {
            output.currentPage = obj.page;
        }
    }

    return output;
}
