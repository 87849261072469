import { userReducer } from '@wearejh/m2-pwa-user/lib/user.reducer';
import { signInEpic } from '@wearejh/m2-pwa-user/lib/epics/signIn.epic';
import { persistUserEpic } from '@wearejh/m2-pwa-user/lib/epics/persist.epic';
import { hydrateEpic } from '@wearejh/m2-pwa-user/lib/epics/hydrate.epic';
import { refreshEpic } from '@wearejh/m2-pwa-user/lib/epics/refresh.epic';
import { resetUserState } from '@wearejh/m2-pwa-user/lib/epics/resetUserState.epic';
import { createAccountEpic } from 'src/features/user/feature/epics/register.epic';

const NAME = 'user';

export function userRegister() {
    return {
        epics: [
            resetUserState,
            hydrateEpic,
            persistUserEpic,
            signInEpic,
            // // signOutOnPageView,
            refreshEpic,
            createAccountEpic,
        ],
        reducers: { [NAME]: userReducer },
        name: NAME,
    };
}
