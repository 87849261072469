import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import environments from 'src/env';
import { readJson } from '@wearejh/m2-pwa-engine/lib/utils/readJson';
import { APP_ENV_STATE_ID } from '@wearejh/m2-pwa-engine/lib/browser/initBrowserApollo';

const appEnv = readJson(APP_ENV_STATE_ID);
const isMaster = appEnv.RELEASE_STAGE === 'production';

const bugsnagClient = Bugsnag.start({
    apiKey: environments[isMaster ? 'master' : appEnv.RELEASE_STAGE].BUGSNAG_ID,
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: appEnv.RELEASE_STAGE,
    plugins: [new BugsnagPluginReact()],
});

export const ErrorBoundary = ({ children }) => {
    const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

    return ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : <div>{children}</div>;
};
