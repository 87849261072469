import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { category, categoryVariables } from 'src/queries/__generated__/category';
import { ApolloError } from 'apollo-client';
import { useDeps } from 'src/hooks/useDeps';
import { CategoryBreadcrumbs } from 'src/types/global-types';

import categoryQuery from '../../queries/category.graphql';

export interface Subcategory {
    id: number | null;
    name: string | null;
    short_description: string | null;
    url_key: string | null;
    image: string | null;
    landing_page: number | null;
}

interface CategoryQueryContextType {
    name: string;
    description: string | null;
    meta_description: string | null;
    productListingDescription?: string;
    meta_title: string | null;
    category_id: number;
    bestSellers: Set<number>;
    title: string;
    urlKey: string;
    loading: boolean;
    error: ApolloError | undefined;
    heroBannerMobile: string | null;
    heroBannerDesktop: string | null;
    subcategories: (Subcategory | null)[] | null;
    landingPage: number | null;
    breadcrumbs?: CategoryBreadcrumbs;
    breadcrumbLabel?: string;
}

const initialData = {
    name: '',
    description: '',
    meta_description: '',
    meta_title: '',
    category_id: -1,
    bestSellers: new Set<number>(),
    title: '',
    urlKey: '',
    loading: false,
    error: undefined,
    heroBannerMobile: '',
    heroBannerDesktop: '',
    subcategories: [],
    landingPage: null,
};

const EMPTY_ARR = [];

export const CategoryQueryContext = React.createContext<CategoryQueryContextType>(initialData);

export const CategoryQueryProvider: React.FC<{ id: number }> = React.memo((props) => {
    const { loading, error, data } = useQuery<category, categoryVariables>(categoryQuery, {
        variables: {
            id: props.id,
        },
        ssr: useDeps().env.SSR_GQL,
    });

    const api: CategoryQueryContextType = useMemo(() => {
        if (!data || !data.category) return { ...initialData, loading, error };
        /**
         * Bestsellers
         */
        const bestSellers = new Set([...((data.category.best_sellers || EMPTY_ARR) as number[])]);

        const {
            name: category_name,
            description,
            meta_title,
            meta_description,
            url_path,
            url_key,
            hero_banner_mobile,
            hero_banner_desktop,
            children,
            landing_page,
            breadcrumbs,
            product_listing_description,
            breadcrumb_label,
        } = data.category;

        const [breadcrumbsFirstElement] = breadcrumbs || [];

        const computedBreadcrumbs = {
            categoryName: breadcrumbsFirstElement?.category_name || '',
            categoryUrl: breadcrumbsFirstElement?.category_url_key || '',
            subcategoryName: category_name || '',
            subcategoryUrl: url_key || '',
            breadcrumbLabel: breadcrumbsFirstElement?.breadcrumb_label,
        };

        return {
            category_id: props.id,
            bestSellers,
            loading,
            error,
            name: category_name || 'unknown',
            description,
            meta_title,
            meta_description,
            urlKey: url_path || 'unknown',
            title: meta_title || category_name || 'unknown',
            heroBannerMobile: hero_banner_mobile,
            heroBannerDesktop: hero_banner_desktop,
            subcategories: children,
            landingPage: landing_page,
            breadcrumbs: computedBreadcrumbs,
            productListingDescription: product_listing_description,
            breadcrumbLabel: breadcrumb_label,
        };
    }, [data, error, loading, props.id]);
    return <CategoryQueryContext.Provider value={api}>{props.children}</CategoryQueryContext.Provider>;
});
