import { from, Observable } from 'rxjs';
import { pluck, withLatestFrom, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action } from 'redux';
import { StorageMsg } from '@wearejh/m2-pwa-engine/lib/storage';
import { StorageActions } from '@wearejh/m2-pwa-engine/lib/storage/index';

import { AppendCSPState, CspState, CspActions, STORAGE_KEY } from '../csp.register';

const ONE_DAY = 60 * 60 * 24 * 1000;
const LOGIN_TIMEOUT = ONE_DAY * 30;

export function cspPersistUserEpic(action$: Observable<any>, state$: Observable<AppendCSPState>): Observable<Action> {
    return action$.pipe(
        ofType<CspActions>('Csp.Persist'),
        withLatestFrom<any, [any, CspState]>(state$.pipe(pluck('csp'))),
        mergeMap(([, csp]) => {
            /**
             * We don't want to persist anything
             */
            const { user, token } = csp.customerServiceRep;
            const { session } = csp;

            const expiry = new Date().getTime() + LOGIN_TIMEOUT;
            const payload = {
                key: STORAGE_KEY,
                value: {
                    session,
                    customerServiceRep: {
                        user,
                        token,
                    },
                },
                expiry,
            };
            return from([StorageMsg(StorageActions.Set, payload)]);
        }),
    );
}
