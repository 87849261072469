import { useEffect, useState } from 'react';

export const useLiveChat = () => {
    const [isLiveChatLoaded, setIsLiveChatLoaded] = useState(false);

    useEffect(() => {
        const checkLiveChatReady = () => {
            if (window['LC_API'] && window['LC_API'].is_loaded() && window['LC_API'].agents_are_available()) {
                setIsLiveChatLoaded(true);
            }
        };

        if (window['LC_API'] && window['LC_API'].is_loaded()) {
            checkLiveChatReady();
        } else {
            const interval = setInterval(() => {
                if (window['LC_API'] && window['LC_API'].is_loaded()) {
                    clearInterval(interval);
                    checkLiveChatReady();
                }
            }, 500);
        }
    }, []);

    const openChatClickHandler = () => {
        if (typeof window !== undefined) {
            window['LC_API']?.open_chat_window();
        }
    };

    return { isLiveChatLoaded, openChatClickHandler };
};
