import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { HeaderVariant } from 'src/components/Header/HeaderVariant';
import { VatProvider } from 'src/components/AppShell/VatProvider';
import { LazyHydrate } from 'src/components/AppShell/LazyHydrate';
import { BrandSchema } from 'src/components/AppShell/BrandStructuredData';
import { CspHeader } from 'src/components/CustomerServicePortal/CspHeader';

import { Splitio } from '../Splitio/Splitio';
import { MainProvider } from '../Main/Main';
import { useDeps } from '../../hooks/useDeps';
import { StoreState } from '../../types/global-types';
import { OutdatedModal } from '../Outdated/Outdated';
import { Scripts } from '../Scripts/Scripts';

import { Head } from './Head';
import { ConfigProvider } from './ConfigProvider';
import { OfflineRibon } from './OfflineRibon';

const InlineSvgs = React.lazy(() => import(/* webpackChunkName:"dev-svg-icons" */ './InlineSvg'));

export const AppShell: React.FC = React.memo((props) => {
    const deps = useDeps();

    return (
        <Splitio>
            <ConfigProvider>
                <BrandSchema />
                <VatProvider>
                    <Head />
                    {deps.env.NODE_ENV !== 'production' && (
                        <React.Suspense fallback={null}>
                            <InlineSvgs />
                        </React.Suspense>
                    )}
                    <CspHeader />
                    <HeaderVariant />
                    <MainProvider>
                        <OfflineOutdated />
                        {props.children}
                    </MainProvider>
                    <div style={{ minHeight: '400px' }}>
                        <LazyHydrate load={() => require('../Footer/Footer').Footer} />
                    </div>
                    <Scripts />
                </VatProvider>
            </ConfigProvider>
        </Splitio>
    );
});

function stateSelector(state: StoreState) {
    return {
        outdated: state.runtime.outdated,
        online: state.runtime.online,
    };
}
function OfflineOutdated() {
    const state = useSelector(stateSelector, shallowEqual);
    return (
        <>
            {state.outdated && <OutdatedModal />}
            {!state.online && <OfflineRibon />}
        </>
    );
}
