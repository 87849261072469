import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

export type ButtonProps = {
    type?: 'button' | 'reset' | 'submit';
    variant?:
        | 'primary'
        | 'secondary'
        | 'bold'
        | 'light'
        | 'alert'
        | 'reset'
        | 'reset-warn'
        | 'outline'
        | 'transparent'
        | 'centered';
    icon?: 'right' | 'center' | 'left-of-text' | 'right-of-text';
    width?: 'auto' | 'max' | 'full';
    size?: 'normal' | 'small';
    shape?: 'normal' | 'circle';
    element?: 'button' | 'a';
    to?: string | { [index: string]: any };
    external?: boolean;
    onClick?(e: any): void;
    className?: string;
    iconSvg?: string;
    [index: string]: any;
};

export const Button: React.FC<ButtonProps> = (props) => {
    const {
        children,
        to,
        element,
        type,
        variant,
        width,
        icon,
        size,
        shape,
        className,
        external,
        iconSvg,
        ...restProps
    } = props;

    const classes = classnames({
        ['button']: true,
        [`button--${variant}`]: true,
        [`button--reset`]: variant === 'reset',
        [`button--transparent`]: variant === 'transparent',
        ['button--width-max']: width === 'max',
        ['button--width-full']: width === 'full',
        ['button--icon-right']: icon && icon === 'right',
        ['button--icon-center']: icon && icon === 'center',
        ['button--icon-left-of-text']: icon && icon === 'left-of-text',
        ['button--icon-right-of-text']: icon && icon === 'right-of-text',
        ['button--size-small']: size && size === 'small',
        ['button--shape-circle']: shape && shape === 'circle',
        ['button--icon-svg']: Boolean(iconSvg),
        [className || '']: true,
    });

    const inner = <span className="button__content">{children}</span>;

    if (element === 'button') {
        return (
            // eslint-disable-next-line
            <button className={classes} type={type} {...restProps}>
                {inner}
                {iconSvg && <img src={`../../../static/icons/${iconSvg}`} />}
            </button>
        );
    }

    if (element === 'a' && to) {
        if (external) {
            return (
                <a href={to as string} className={classes} {...restProps}>
                    {inner}
                </a>
            );
        }
        return (
            <Link to={to} className={classes} {...restProps}>
                {inner}
                {iconSvg && <img src={`../../../static/icons/${iconSvg}`} />}
            </Link>
        );
    }

    return null;
};

Button.defaultProps = {
    type: 'button',
    variant: 'primary',
    width: 'auto',
    size: 'normal',
    shape: 'normal',
    element: 'button',
    external: false,
};
