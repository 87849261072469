import { defer, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { EpicDeps, DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { ApolloClient } from 'apollo-client';
import { getQuery } from '@wearejh/m2-pwa-cart-gql/lib/utils/getQuery';
import {
    CartConfigOption,
    CartItemPrice,
    CartBundleOption,
    CartTax,
    CartDiscount,
} from '@wearejh/m2-pwa-cart-gql/lib/index';
import {
    getCart,
    getCart_getCart,
    getCart_getCart_items,
    getCart_getCart_prices_applied_taxes,
    getCart_getCart_prices_discounts,
    getCartVariables,
} from 'src/queries/__generated__/getCart';
import { createEmptyCart } from '@wearejh/m2-pwa-cart-gql/lib/queries/__generated__/createEmptyCart';
import createCartMutation from '@wearejh/m2-pwa-cart-gql/lib/queries/createCart.graphql';
import { CartProduct } from 'src/types/global-types';

export function fetchCart(cartId: string | null, deps: EpicDeps): Observable<CartFetchParams> {
    if (!cartId) {
        return createCart(deps).pipe(mergeMap((cartId) => fromId(cartId, deps)));
    }
    return fromId(cartId, deps);
}

function fromId(cartId: string, deps: EpicDeps) {
    return getCartAjax(cartId, deps).pipe(map((cart) => processResp(cart)));
}

type CartTotals = {
    grand_total: number;
    subtotal_including_tax: number;
    subtotal_excluding_tax: number;
    discounts: CartDiscount[];
    applied_taxes: CartTax[];
    userOrdersTotal?: string;
};

type CartItem =
    | {
          __typename: 'ConfigurableCartItem';
          id: string;
          prices: CartItemPrice;
          quantity: number;
          product: CartProduct;
          configurable_options: CartConfigOption[];
      }
    | {
          __typename: 'BundleCartItem';
          id: string;
          prices: CartItemPrice;
          quantity: number;
          product: CartProduct;
          bundle_options: CartBundleOption[];
      }
    | {
          __typename: 'SimpleCartItem';
          id: string;
          prices: CartItemPrice;
          quantity: number;
          product: CartProduct;
      }
    | any;

type CartState<T extends any = Record<string, any>> = {
    status: DataStatus;

    cartId: string | null;
    items_qty: number;
    totals: CartTotals | null;
    items: CartItem[];

    messages: Message[];
    itemMessages: Message[];
    deleting: number[];
    updating: number[];

    raw: null | T;
    userOrdersTotal?: string;
};

type CartFetchParams = Pick<CartState, 'cartId' | 'totals' | 'items' | 'items_qty' | 'raw'>;

export function processResp(cart: getCart_getCart): CartFetchParams {
    if (!cart.prices) throw new Error('cart.prices is missing');
    if (!cart.items || !Array.isArray(cart.items)) throw new Error("cart.items is missing or isn't an array");
    const { prices } = cart;
    const discounts = (prices.discounts || []) as GqlDiscounts[];
    const taxes = (prices.applied_taxes || []) as GqlTaxes[];
    return {
        raw: cart,
        cartId: cart.id,
        totals: {
            discounts: discounts.map((dis) => {
                return {
                    amount: dis.amount.value ?? 0,
                    label: dis.label || 'unknown',
                };
            }),
            grand_total: prices.grand_total?.value ?? 0,
            subtotal_including_tax: prices.subtotal_including_tax?.value ?? 0,
            subtotal_excluding_tax: prices.subtotal_excluding_tax?.value || 0,
            applied_taxes: taxes.map((t) => {
                return {
                    label: t.label || 'unknown tax label',
                    amount: t.amount.value ?? 0,
                };
            }),
            userOrdersTotal: cart.orders_total,
        },

        items_qty: cart.total_quantity,
        items: (cart.items as GqlItem[]).map(
            (item): CartItem => {
                const product = {
                    __typename: item.product.__typename,
                    name: item.product.name!,
                    sku: item.product.sku!,
                    small_image: {
                        label: item.product.small_image?.label || 'unknown',
                        url: item.product.small_image?.url || 'https://placehold.it/200',
                    },
                    locations: item.product.locations,
                    pathname:
                        (item.product.url_rewrites &&
                            item.product.url_rewrites[0] &&
                            item.product.url_rewrites[0].url) ||
                        '/unknown',
                    logo_policy: item.product.logo_policy,
                };
                const prices = {
                    price: item.prices!.price.value!,
                    row_total: item.prices!.row_total.value!,
                    row_total_including_tax: item.prices!.row_total_including_tax.value!,
                };
                switch (item.__typename) {
                    case 'ConfigurableCartItem': {
                        return {
                            __typename: item.__typename,
                            id: item.id,
                            quantity: item.quantity,
                            product,
                            prices,
                            configurable_options: item.configurable_options as CartConfigOption[],
                        };
                    }
                    case 'SimpleCartItem': {
                        return {
                            __typename: item.__typename,
                            id: item.id,
                            quantity: item.quantity,
                            product,
                            prices,
                        };
                    }
                    case 'BundleCartItem': {
                        return {
                            __typename: item.__typename,
                            id: item.id,
                            quantity: item.quantity,
                            product,
                            prices,
                            bundle_options: [],
                        };
                    }
                    default: {
                        return {
                            __typename: item.__typename as any,
                            id: item.id,
                            quantity: item.quantity,
                            product,
                            prices,
                        };
                    }
                }
            },
        ),
    };
}

function createCart(deps: EpicDeps): Observable<string> {
    const client = deps.client as ApolloClient<any>;
    return defer(() => {
        return client.mutate<createEmptyCart>({
            mutation: createCartMutation,
            operationName: 'createEmptyCart',
        } as any);
    }).pipe(map((x) => x.data?.createEmptyCart || 'unknown'));
}

function getCartAjax(cartId: string, deps: EpicDeps) {
    const client = deps.client as ApolloClient<any>;
    return defer(() => {
        return client.query<getCart, getCartVariables>({
            query: getQuery('getCart', deps),
            operationName: 'getCart',
            variables: {
                cartId,
            },
            fetchPolicy: 'no-cache',
        } as any);
    }).pipe(map((x) => x.data?.getCart as getCart_getCart));
}

type GqlItem = getCart_getCart_items;
type GqlDiscounts = getCart_getCart_prices_discounts;
type GqlTaxes = getCart_getCart_prices_applied_taxes;
