import React, { useEffect, useState } from 'react';

type Props = {
    minHeight?: string;
    delay?: number;
};

export const Delay: React.FC<Props> = React.memo((props) => {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        const int = setTimeout(() => setReady(true), props.delay);
        return () => {
            clearTimeout(int);
        };
    }, [props.delay]);
    if (!ready) return <div style={{ minHeight: props.minHeight }} />;
    return props.children as any;
});

Delay.defaultProps = {
    minHeight: '80vh',
};
