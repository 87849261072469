import { useDispatch } from 'react-redux';
import { useEpicDeps } from '@wearejh/m2-pwa-engine/lib/hooks/useEpicDeps';
import { useRouterLocationCausesRender } from 'src/hooks/router-hooks';
import { useCallback, useMemo } from 'react';
import { categoryProductsVariables } from 'src/queries/__generated__/categoryProducts';
import { createDebug } from 'src/util/debug';

import { MappedAggregations, productFiltersFromSearch } from '../utils/cleanAggregations';
import { deserializeUrl } from '../utils/deserializeUrl';

export type ProductFiltersApi = {
    initialQueryVars: FilterVars;
    setSortOrder: (param: string, order: string) => void;
    clearSort: () => void;
    createPageUrl: (num: number) => string;
    setSortTag: (order: string) => void;
};

const debug = createDebug('useProductFilters');

export function useProductFilters(category_id: string | number, mappedAggregations: MappedAggregations) {
    const dispatch = useDispatch();
    const { replace } = useEpicDeps();
    const { pathname, search } = useRouterLocationCausesRender();

    debug('%o', { pathname, search });

    const productFilters = useMemo(() => productFiltersFromSearch(mappedAggregations, search, String(category_id)), [
        category_id,
        mappedAggregations,
        search,
    ]);

    const setSortOrder = useCallback(
        (param: string, order: string) => {
            const params = new URLSearchParams(search);

            if (params.has('dir')) {
                params.set('dir', order);
            } else {
                params.append('dir', order);
            }
            if (params.has('order')) {
                params.set('order', param);
            } else {
                params.append('order', param);
            }

            const nextUrl = `${pathname}?${params.toString()}`;
            dispatch(replace(nextUrl));
        },
        [dispatch, pathname, replace, search],
    );

    const createPageUrl = useCallback(
        (num: number) => {
            const params = new URLSearchParams(search);
            if (params.has('page')) {
                params.set('page', String(num));
            } else {
                params.append('page', String(num));
            }
            return `${pathname}?${params.toString()}`;
        },
        [pathname, search],
    );

    const clearSort = useCallback(() => {
        const params = new URLSearchParams(search);
        if (params.has('order')) {
            params.delete('order');
            params.delete('dir');
            const nextUrl = `${pathname}?${params.toString()}`;
            dispatch(replace(nextUrl));
        }
    }, [dispatch, pathname, replace, search]);

    const initialQueryVars: FilterVars = {
        productFilters,
        ...deserializeUrl(search),
    };

    return { initialQueryVars, setSortOrder, clearSort, createPageUrl, pathname };
}

export type FilterVars = categoryProductsVariables;
