import React, { useEffect, ImgHTMLAttributes, useRef, HTMLAttributes } from 'react';
import lozad from 'lozad';

export function useLazyRef(ref: React.RefObject<any>) {
    useEffect(() => {
        const domNode = ref.current;
        const observer = lozad(domNode, {
            rootMargin: '200px',
        });
        observer.observe();
        return () => {
            if (observer.observer && domNode) {
                observer.observer.unobserve(domNode);
            }
        };
    }, [ref]);
}

export const LazyImg: React.FC<ImgHTMLAttributes<any>> = (props) => {
    const { src, ...rest } = props;
    const ref = useRef<HTMLImageElement | null>(null);
    useLazyRef(ref);
    return <img data-src={src} {...rest} ref={ref} data-lazy-load />;
};

export const LazyPicture: React.FC<HTMLAttributes<any>> = (props) => {
    const ref = useRef<HTMLPictureElement | null>(null);
    useLazyRef(ref);
    return (
        <picture ref={ref} {...props}>
            {props.children}
        </picture>
    );
};

export const LazyBackground: React.FC<{ src: string } & HTMLAttributes<any>> = (props) => {
    const { src, ...rest } = props;
    const ref = useRef<HTMLDivElement | null>(null);
    useLazyRef(ref);
    return (
        <div ref={ref} data-background-image={src} {...rest}>
            &nbsp;
        </div>
    );
};
