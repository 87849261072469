import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDeps } from 'src/hooks/useDeps';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { VAT_STORAGE_KEY } from 'src/components/AppShell/app-shell.types';

import { useSearch } from '../../hooks/router-hooks';

const noop = (name: string) => {
    return () => {
        // eslint-disable-next-line no-console
        console.log(`${name} is missing - did you include the register function for it?`);
        return undefined;
    };
};

type Props = Record<string, unknown>;

export const VatContext = React.createContext<{
    vat$: BehaviorSubject<boolean>;
    setVat: (vat: boolean) => void;
}>({
    vat$: new BehaviorSubject<boolean>(false),
    setVat: noop('set vat'),
});

export const VatProvider: React.FC<Props> = React.memo((props) => {
    const search = useSearch();
    const isVatFromURL = Boolean(new URLSearchParams(search).get('vat'));
    const { storage } = useDeps();

    const initial = isVatFromURL || (storage.get(VAT_STORAGE_KEY) ?? false);

    const vat$ = useConstant(() => new BehaviorSubject(initial));

    const setVat = useCallback(
        (vat: boolean) => {
            vat$.next(vat);
            /**
             * Note: this is NOT a value being sent to redux
             * like normal as we would with the cart/user
             * etc because this value is simply not important enough.
             * If it goes away then it defaults to false anyway, so no issue.
             */
            storage.set(VAT_STORAGE_KEY, vat);
        },
        [storage, vat$],
    );

    setVat(initial);

    const api = useMemo(() => {
        return { vat$, setVat };
    }, [setVat, vat$]);

    return <VatContext.Provider value={api}>{props.children}</VatContext.Provider>;
});

/**
 * Use this when you want a change in VAT to cause your components to re-render
 */
export function useVatCausesRender(): { vat: boolean; setVat: (vat: boolean) => void } {
    const { vat$, setVat } = useContext(VatContext);
    const [vat, setVatLocal] = useState(vat$.value);
    useEffect(() => {
        const sub = vat$.pipe(distinctUntilChanged(), tap(setVatLocal)).subscribe();
        return () => sub.unsubscribe();
    }, [vat$]);

    return { vat, setVat };
}
