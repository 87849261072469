import React from 'react';
import classnames from 'classnames';

import { Svg } from '../Svg/Svg';
import { Button } from '../Button/Button';
import { SvgIconNames } from '../Svg/icons-types';

import classes from './Modal.scss';
type Props = {
    title: string;
    icon?: SvgIconNames;
    iconOnRight?: boolean;
    type?: 'success' | 'default';
    onDismiss?(...args: any[]): void;
    isDismissButton?: boolean;
    controlScroll?: boolean;
    subTitle?: string;
    className?: string;
    titleClassName?: string;
    filter?: string;
    removeFilters?: () => void;
    filtersSelected?: boolean;
};

export const ModalHeader: React.FC<Props> = (props) => {
    const {
        isDismissButton,
        onDismiss,
        type,
        title,
        titleClassName,
        subTitle,
        icon,
        iconOnRight,
        className,
        filter,
        removeFilters,
        filtersSelected,
    } = props;

    return (
        <>
            {isDismissButton && (
                <div className={classes.modalHeaderDismiss} onClick={onDismiss}>
                    <Svg name="arrow-left" className={classes.modalHeaderDismissIcon} />
                    <span>Back</span>
                </div>
            )}
            <header
                className={classnames(className, {
                    [classes.modalHeader]: true,
                    [classes.modalHeaderSuccess]: type === 'success',
                })}
            >
                {icon && !iconOnRight && <Svg name={icon} className={classes.titleIcon} />}
                <div className={classnames(titleClassName, classes.modalTitleWrapper)}>
                    {filter && <img src={require(`src/components/Svg/svgs/${filter}.svg`)} alt={`${filter} icon`} />}
                    <h2
                        className={classnames({
                            [classes.modalTitle]: true,
                            [classes.modalTitleSuccess]: type === 'success',
                        })}
                    >
                        {title}
                    </h2>
                    {subTitle && <p className={classes.modalSubTitle}>{subTitle}</p>}
                </div>
                {icon && iconOnRight && (
                    <Svg name={icon} className={classnames(classes.titleIcon, classes.titleIconRight)} />
                )}
                {removeFilters && filtersSelected && (
                    <div className={classes.modalRemoveFilters}>
                        <span onClick={removeFilters}>Clear filters</span>
                    </div>
                )}
                {onDismiss && !isDismissButton && (
                    <Button
                        onClick={() => {
                            if (props.controlScroll) {
                                return onDismiss(false);
                            }
                            return onDismiss();
                        }}
                        variant="reset"
                        className={classes.modalClose}
                    >
                        <Svg name="close" />
                    </Button>
                )}
            </header>
        </>
    );
};
