import { useCallback, useEffect, useState } from 'react';
import { useLazyModals } from 'src/components/SignIn/LazyModals';
import { useSearch } from 'src/hooks/router-hooks';
import { useDispatch } from 'react-redux';
import { useDeps } from 'src/hooks/useDeps';
import { useMutation } from '@apollo/react-hooks';
import {
    verifyCustomerAccessToken,
    verifyCustomerAccessTokenVariables,
} from 'src/queries/__generated__/verifyCustomerAccessToken';
import { UserMsg } from '@wearejh/m2-pwa-user';
import jwt_decode from 'jwt-decode';
import { formatGraphqlError } from 'src/components/SignIn/MagicLink';
import Bugsnag from '@bugsnag/js';

import verifyCustomerAccessTokenQuery from '../../src/queries/verifyCustomerAccessToken.graphql';

const isValidToken = (token: string) => {
    try {
        return Boolean(jwt_decode(token));
    } catch (error) {
        Bugsnag.notify(error);
        return false;
    }
};

export const useMagicLink = () => {
    const search = useSearch();
    const accessToken = new URLSearchParams(search).get('token') || '';
    const [errorBody, setErrorBody] = useState('');
    const { components, dispatch } = useLazyModals({ errorBody });
    const dispatchRedux = useDispatch();
    const deps = useDeps();

    const [tokenQuery] = useMutation<verifyCustomerAccessToken, verifyCustomerAccessTokenVariables>(
        verifyCustomerAccessTokenQuery,
        {
            onCompleted: async (data) => {
                const newToken = data.verifyCustomerAccessToken?.token as string;
                dispatchRedux(UserMsg('User.SignInSetToken', { token: newToken }));

                const url = deps.apiUrl('customerCustomerRepositoryV1GetByIdGet')('/V1/customers/me', undefined);
                try {
                    const getNewToken = await fetch(url, {
                        headers: {
                            Authorization: `Bearer ${newToken}`,
                        },
                    });
                    const dataWithNewToken = await getNewToken.json();

                    dispatch({ kind: 'magicLinkConfirmation' });

                    setTimeout(() => {
                        dispatchRedux(UserMsg('User.SignInSuccess', dataWithNewToken));
                        dispatchRedux(UserMsg('User.Persist'));

                        const redirectPath = localStorage.getItem('magicFrom');
                        if (redirectPath) {
                            localStorage.removeItem('magicFrom');
                            dispatch({ kind: 'close' });
                            dispatchRedux(deps.replace(redirectPath));
                        }
                    }, 1000);
                } catch (e) {
                    Bugsnag.notify('Error: Could not load through magic link.', (event) => {
                        event.addMetadata('errorObject', e);
                    });
                    setErrorBody('We could not log you in. Please create new passwordless link');
                }
            },
            onError: (error) => {
                const errorMsg = formatGraphqlError(error);
                Bugsnag.notify(errorMsg, (event) => {
                    event.addMetadata('errorObject', error);
                });

                setErrorBody('We could not log you in. Please create new passwordless link');
                dispatch({ kind: 'magicLinkError' });
            },
        },
    );

    const magicLogin = useCallback(async () => {
        await tokenQuery({
            variables: {
                token: accessToken,
            },
        });
    }, [tokenQuery, accessToken]);

    useEffect(() => {
        if (accessToken && isValidToken(accessToken)) {
            magicLogin();
        }
    }, [magicLogin, accessToken]);

    return {
        components,
        dispatch,
    };
};
