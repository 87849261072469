export const APPROVED_AGGREGATIONS_LIST = [
    'colour_family',
    'filter_size_clothing_international',
    'brand',
    'filter_size_footwear_uk',
];
export const UNIVERSAL_FILTER_NAME = ['filter_size_clothing_international', 'filter_size_footwear_uk'];
export const FILTER_WITH_SEARCHER = 'brand';
export const STATIC_FILTERS_COUNT = 3;
export const HIDE_FILTERS_COUNT = 1;
