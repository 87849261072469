import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { NavSubMenu } from './NavSub';
import classes from './NavSub.scss';

type Props = {
    items: NavSubMenu[];
};

export const AttributesNav: React.FC<Props> = ({ items }) => {
    return (
        <div className={classes.navSubContentWrapper}>
            {items.map((item) => (
                <div key={item.title} className={classes.navSubContent}>
                    <p className={classes.navSubTitle}>{item.title}</p>
                    {item.children.length > 0 && (
                        <ul className={classes.navSubList}>
                            {item.children.map((subSubItem, i) =>
                                /VIEW/i.test(subSubItem.title) ? (
                                    <div key={subSubItem.title}>
                                        <div className={classes.spacer} />
                                        <li
                                            key={subSubItem.title}
                                            className={classnames(classes.navSubItem, classes.navSubItemView)}
                                        >
                                            <Link to={subSubItem.url} className={classes.navSubLink}>
                                                View All
                                            </Link>
                                        </li>
                                    </div>
                                ) : (
                                    <li
                                        key={subSubItem.title}
                                        className={classnames(classes.navSubItem, {
                                            [classes.navSubItemNoBorder]: i === item.children.length - 2,
                                        })}
                                    >
                                        <Link to={subSubItem.url} className={classes.navSubLink}>
                                            {subSubItem.title}
                                        </Link>
                                    </li>
                                ),
                            )}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    );
};
