import { ActionMap, createMsg } from 'action-typed';
import { cspCustomerSignInEpic } from 'src/components/CustomerServicePortal/feature/epics/cspCustomerSignIn.epic';
import { cspCustomerRegisterEpic } from 'src/components/CustomerServicePortal/feature/epics/cspCustomerRegister.epic';
import { CspRegisterParams } from 'src/components/CustomerServicePortal/hooks/useCspLogin';

export const NAME = 'cspUser';

export type Messages = {
    ['CspUser.SignIn']: { emailAddress: string; cspToken: string };
    ['CspUser.Register']: CspRegisterParams;
};

export function cspCustomerSignIn() {
    return {
        epics: [cspCustomerSignInEpic, cspCustomerRegisterEpic],
        name: NAME,
        reducers: {
            [NAME]: (s = {}) => s,
        },
    };
}

export const Msg = createMsg<Messages>();
export const CspCustomerSignInMsg = Msg;

export type TypeMap = ActionMap<Messages>;
export type CspActions = TypeMap[keyof TypeMap];
export type AppendState<T = Record<string, unknown>> = T & { cspUser };
