import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Delay } from 'src/components/Helpers/Delay';

function interopDefault(mod) {
    return (mod && mod.default) || mod;
}

export function ServerHydrator({ load, ...props }) {
    const Child = interopDefault(load());
    return (
        <section>
            <Child {...props} />
        </section>
    );
}

const EMPTY_HTML = { __html: '' };

export class Hydrator extends Component {
    root: any;
    props: {
        delay?: number;
        minHeight?: string;
        [index: string]: any;
    } = {};
    shouldComponentUpdate() {
        return false;
    }

    componentDidMount() {
        if (!(this.root instanceof Element)) {
            console.warn('bailing from intersection observer since element did not exist');
            return;
        }
        new IntersectionObserver(async (args, obs) => {
            const [entry] = args;
            if (!entry.isIntersecting) return;
            if (this.root) {
                obs.unobserve(this.root);
            }

            const { load, minHeight, ...props } = this.props;
            const Child = interopDefault(await load());
            const next = props.delay ? (
                <Delay delay={props.delay} minHeight={minHeight}>
                    <Child {...props} />
                </Delay>
            ) : (
                <Child {...props} />
            );

            ReactDOM.hydrate(<Provider context={this.context}>{next}</Provider>, this.root);
        }).observe(this.root);
    }

    render() {
        const { delay, ...rest } = this.props;
        return <section ref={(c) => (this.root = c)} dangerouslySetInnerHTML={EMPTY_HTML} {...rest} />;
    }
}

class Provider extends Component<any> {
    getChildContext() {
        return (this.props as any).context;
    }
    render() {
        return this.props.children;
    }
}
