import { concat, Observable, of } from 'rxjs';
import { Action } from 'redux';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { StorageActions, StorageMsg } from '@wearejh/m2-pwa-engine/lib/storage/storage.actions';

import { STORAGE_KEY, CspActions } from '../csp.register';

export function cpsSignOut(action$: Observable<any>): Observable<Action> {
    const signOut$ = action$.pipe(ofType<CspActions>('Csp.SignOut'));
    /**
     * Merge the streams to perform the sign-out on both
     */
    return signOut$.pipe(
        mergeMap(() => {
            return concat(
                of(StorageMsg(StorageActions.Delete, STORAGE_KEY), StorageMsg(StorageActions.Delete, 'cspCustomer')),
            );
        }),
    );
}
