import React from 'react';
import classnames from 'classnames';

import classes from './Forms.scss';

type FieldProps = {
    children: React.ReactNode;
    class?: string;
};

export const Field: React.FC<FieldProps> = (props) => {
    return <div className={classnames(classes.field, props.class)}>{props.children}</div>;
};
