import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';
import { Button } from 'src/components/Button/Button';
import { useLiveChat } from 'src/components/WhatsAppChat/hooks/useLiveChat';

import { Container, Wrapper } from '../Layout';

import classes from './NotFound.scss';

type NotFoundProps = Record<string, unknown>;

export const NotFound: React.FC<NotFoundProps> = () => {
    useSetResolved();
    const { isLiveChatLoaded, openChatClickHandler } = useLiveChat();

    return (
        <>
            <Helmet>
                <title>404 Not Found</title>
            </Helmet>
            <Wrapper variant="page-inner">
                <Container>
                    <div className={classes.header}>
                        <h1>Can't find what you are looking for?</h1>
                        <p>Well, that's a bit of a pickle!</p>
                        <p>This page isn't here, but our friendly team is. Use the links below to get back on track</p>
                        <a className={classes.goBack} href="#" onClick={() => history.go(-1)}>
                            Go Back
                        </a>
                    </div>
                    <div className={classes.widgets}>
                        <div className={classes.widget}>
                            <div className={classes.title}>
                                <img src="../../../static/icons/moreAlternatives.svg" alt="need alternatives?" />
                                <h2>Need Alternatives? Let's Talk!</h2>
                            </div>
                            <p>
                                Can’t see it here? <br />
                                Contact us for recommendations and <br />
                                similar options to keep your team outfitted. <br />
                                We’re here to make sure nothing slows you down.
                            </p>
                            <Button
                                iconSvg="v-right_orange.svg"
                                variant="bold"
                                element="button"
                                icon="right"
                                onClick={() => {
                                    if (isLiveChatLoaded) {
                                        openChatClickHandler();
                                    }
                                }}
                            >
                                Enquire Now
                            </Button>
                        </div>
                        <div className={classes.widget}>
                            <div className={classes.title}>
                                <img
                                    src="../../../static/icons/bulk&CorporatePage.svg"
                                    alt="big order? bigger solutions!"
                                />
                                <h2>Big Order? Bigger Solutions!</h2>
                            </div>
                            <p>
                                For large or corporate orders, we’ve got the <br />
                                solutions you need to keep your team looking sharp. <br />
                                Let us take the stress out of big orders.
                            </p>
                            <Button
                                iconSvg="v-right_orange.svg"
                                variant="bold"
                                element="a"
                                icon="right"
                                to="/bulk-and-corporate"
                            >
                                Enquire Now
                            </Button>
                        </div>
                        <div className={classes.widget}>
                            <div className={classes.title}>
                                <img src="../../../static/icons/needMoreInfo.svg" alt="need more info?" />
                                <h2>Need More Info? Get in Touch!</h2>
                            </div>
                            <p>
                                Got queries? Let’s chat and find the right <br />
                                solutions together. <br />
                                Our team is always ready to make your <br />
                                workwear journey hassle-free.
                            </p>
                            <Button
                                iconSvg="v-right_orange.svg"
                                variant="bold"
                                element="a"
                                icon="right"
                                to="/contact-us"
                            >
                                Contact Now
                            </Button>
                        </div>
                    </div>
                </Container>
            </Wrapper>
        </>
    );
};
