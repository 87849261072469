import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "Create customer account. Perform necessary business operations like sending email.";
export const method = "POST";
export const operationId = "customerAccountManagementV1CreateAccountPost";
export const path = "/V1/customers";
export interface Body {
    customer: Definitions.CustomerDataCustomerInterface;
    password?: string;
    redirectUrl?: string;
}
export type Response = Response200 | Response500 | ResponseDefault;
export type Response200 = Definitions.CustomerDataCustomerInterface;
export type RespKeys = (keyof Definitions.CustomerDataCustomerInterface)[];
export type Response500 = Definitions.ErrorResponse;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "postJSON">;
export function execute<T extends RespKeys | undefined>(body: Body, effects: MinimalEffects, fields?: T): T extends RespKeys ? Observable<Pick<Definitions.CustomerDataCustomerInterface, T[number]>> : Observable<Response200> {
    return effects.postJSON(effects.apiUrl(operationId)(path, undefined, fields), body, effects.restHeaders({})) as any;
}
