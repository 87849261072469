import React from 'react';
import { Svg } from 'src/components/Svg/Svg';
import { contact } from 'src/config.json';
import classnames from 'classnames';

import classes from './FooterAction.scss';

type Props = {
    className?: string;
};

export const FooterAction: React.FC<Props> = ({ className }) => (
    <p className={classnames(classes.footerAction, className)}>
        <Svg role="img" name="phone" />
        Call us free on <a href={`tel:${contact.phone}`}>{contact.phone}</a>
    </p>
);
