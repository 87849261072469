import React from 'react';
import { useNextTick } from 'src/hooks/useNextTick';

type LazyLoaderProps = {
    loader: React.ReactElement;
    fallback: React.ReactElement | null;
};

export const LazyLoader: React.FC<LazyLoaderProps> = React.memo((props) => {
    const ready = useNextTick();
    if (!ready) return props.fallback;
    return <React.Suspense fallback={props.fallback}>{props.loader}</React.Suspense>;
});
