import React from 'react';
import { Link } from 'react-router-dom';
import { NUMBER_OF_FEATURED_IMAGES } from 'src/constants/navigation';

import { NavSubMenu } from './NavSub';
import classes from './NavSub.scss';

type Props = {
    items: NavSubMenu[];
};

export const BrandsNav: React.FC<Props> = ({ items }) => {
    const computedItems = items.find((el) => el.title === 'Brands images');
    const imageItems = computedItems?.children.slice(0, NUMBER_OF_FEATURED_IMAGES);

    const getHrefValueFromString = (item) => {
        return item.match(/href="([^"]*)/) ? item.match(/href="([^"]*)/)[1] : '';
    };

    return (
        <div className={classes.navSubContentWrapper}>
            <div className={classes.navSubTitles}>
                <p className={classes.navSubTitle}>Popular Brands</p>
                {computedItems && (
                    <>
                        <div className={classes.navSubContentGrid}>
                            {computedItems.children.map((item) => (
                                <Link key={item.title} to={getHrefValueFromString(item.html)}>
                                    {item.title}
                                </Link>
                            ))}
                        </div>
                        <div className={classes.navSubContentGrid}>
                            {computedItems.url && (
                                <Link to={computedItems.url} className={classes.navSubLinkBrands}>
                                    View all brands
                                </Link>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={classes.navSubImages}>
                <p className={classes.navSubTitle}>Featured Brands</p>
                {imageItems && (
                    <div className={classes.navSubContentBrands}>
                        {imageItems.map(
                            (item) =>
                                item.html && (
                                    <div
                                        key={item.title}
                                        className={classes.navSubImage}
                                        dangerouslySetInnerHTML={{ __html: item.html }}
                                    />
                                ),
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
