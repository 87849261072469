import React from 'react';
import { Timer } from 'src/components/Timer/Timer';
import ReactTooltip from 'react-tooltip';

import classes from './Banner.scss';

type BannerProps = {
    title: string;
    description: string;
    timerSeconds?: number;
    bgColour?: string;
    fontColour?: string;
    link?: string;
    discountCode?: string | null;
    url?: string | null;
};

const ContentWithUrl: React.FC<{ url: string }> = ({ url, children }) => (
    <a href={url} className={classes.urlContent}>
        {children}
    </a>
);

export const Banner: React.FC<BannerProps> = ({
    title,
    description,
    timerSeconds,
    bgColour,
    fontColour,
    discountCode,
    url,
}) => {
    const onCopyClick = (discountCode: string) => {
        navigator.clipboard.writeText(discountCode);
        setTimeout(() => {
            ReactTooltip.hide();
        }, 3000);
    };

    const titleAndDescriptionContent = () => (
        <span className={classes.content}>
            {title}: {description}
        </span>
    );

    return (
        <strong className={classes.banner} style={{ background: bgColour, color: fontColour }} data-test-id="Banner">
            {url ? (
                <ContentWithUrl url={url}>{titleAndDescriptionContent()}</ContentWithUrl>
            ) : (
                titleAndDescriptionContent()
            )}
            {discountCode ? (
                <>
                    <div className={classes.discountCode} style={{ background: fontColour, color: bgColour }}>
                        <span>{discountCode}</span>
                        <span
                            className={classes.border}
                            style={{ borderColor: bgColour }}
                            data-testid="discountCodeBorder"
                        />
                        <button data-tip="Copied to clipboard!" type="button" onClick={() => onCopyClick(discountCode)}>
                            Copy Code
                        </button>
                    </div>
                    <ReactTooltip type="dark" effect="solid" event="click" />
                </>
            ) : (
                timerSeconds && <Timer title=" Ends in:" className={classes.timer} initialTime={timerSeconds} />
            )}
        </strong>
    );
};
