import { onError } from 'apollo-link-error';
import { GraphQLError } from 'graphql/error/GraphQLError';
import Bugsnag from '@bugsnag/js';

export const suppressGqlErrors = onError(({ operation, graphQLErrors, response }) => {
    if (graphQLErrors) {
        if (
            operation.operationName === 'productSearchAlgolia' &&
            response &&
            graphQLErrors[0].message === 'Internal server error'
        ) {
            response.errors = [];
            return;
        }
        // When we go 'footwear/mens-footwear' and then sort (Low - High) we have an error and data. Ignore error for now
        if (
            operation.operationName === 'categoryProducts' &&
            response &&
            graphQLErrors[0].message === 'Internal server error'
        ) {
            response.errors = [];
            return;
        }

        // REMOVE ITEM
        if (operation.operationName === 'removeItemFromCart' && response) {
            const copyErrors = response.errors;
            copyErrors?.map((el) => {
                switch (true) {
                    case el.message.includes('Could not find a basket with ID'):
                        return (el.message = 'Could not delete the item. Please try again.');
                    case el.message.includes("Basket doesn't contain"):
                        return (el.message = 'Could not find the item in the card. Please try again.');
                }
            });
        }

        // UPDATE ITEM
        if (operation.operationName === 'updateItem' && response) {
            const copyErrors = response.errors;
            copyErrors?.map((el) => {
                switch (true) {
                    case el.message.includes('The requested qty exceeds the maximum qty allowed in shopping basket'):
                        return (el.message =
                            'The requested quantity exceeds available amount. Please adjust and try again.');
                    case el.message.includes('Variable "$input" got invalid value'):
                        return (el.message = 'Could not update the item. Please try again.');
                }
                return el;
            });
        }

        // ADD ITEM
        if (operation.operationName === 'addConfigurable' && response) {
            const copyErrors = response.errors;
            copyErrors?.map((el) => {
                switch (true) {
                    case el.message === 'Some of the products are out of stock.':
                        return (el.message =
                            'There is an out of stock item already in your basket. Please remove it to add new items.');
                    case el.message.includes('Could not find a basket with ID'):
                        return (el.message = 'Could not find basket. Please try again.');
                    case el.message.includes('Required parameter '):
                        return (el.message = 'Could not add item. Please try again.');
                    case el.message.includes('The requested qty exceeds the maximum qty allowed in shopping basket'):
                        return (el.message =
                            'The requested quantity exceeds available amount. Please adjust and try again.');
                    case el.message.includes('Could not find specified product.'):
                    case el.message.includes('Could not find a product with SKU'):
                        return (el.message =
                            'Could not find specified product. Please try again or choose a different product.');
                }
                return el;
            });
            response.errors = copyErrors;
            return;
        }

        if (operation.operationName === 'promoBanner' && response) {
            // eslint-disable-next-line no-console
            console.log('removing promoBanner errors');
            response.errors = [];
            return;
        }

        if (response) {
            if (response.errors?.some((e) => e.path?.some((p) => p === 'size_guides'))) {
                // eslint-disable-next-line no-console
                console.log('removing size_guides errors');
                response.errors = [];
                return;
            }
        }

        if (
            (operation.operationName === 'productList' ||
                operation.operationName === 'productDetail' ||
                operation.operationName === 'bundleProductDetail') &&
            response
        ) {
            if (errorPathHas('media_gallery', response.errors)) {
                // eslint-disable-next-line no-console
                console.log('removing media_gallery errors from `%s`', operation.operationName);
                response.errors = [];
                return;
            }
        }

        if (operation.operationName === 'categoryProducts' && response) {
            if (errorPathHas('price_range', response.errors)) {
                // eslint-disable-next-line no-console
                console.log('removing price_range errors from `%s`', operation.operationName);
                response.errors = [];
                return;
            }
            if (response.errors?.some((e) => e.message.indexOf('"$productFilters"') > -1)) {
                // eslint-disable-next-line no-console
                console.log('removing $productFilters errors from `%s`', operation.operationName);
                response.errors = [];
                return;
            }
        }

        if (
            response?.errors?.some((e) => {
                const match = e.path?.some((p) => p === 'swatch_data');
                if (match) {
                    Bugsnag.notify('Error suppressed from operation', (event) => {
                        event.addMetadata('errorData', { operationName: operation.operationName, error: e });
                    });
                    return true;
                }
                return false;
            })
        ) {
            response.errors = [];
            return;
        }
    }
});

function errorPathHas(search: string, errors?: readonly GraphQLError[]): boolean {
    return Boolean(errors?.some((e) => e.path?.some((p) => p === search)));
}
