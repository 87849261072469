import React, { useCallback, useEffect } from 'react';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router/useSetResolved';

import { Button } from '../Button/Button';

import classes from './Outdated.scss';

export const Outdated = () => {
    useSetResolved();
    useEffect(() => {
        const int = setTimeout(() => window.location.reload(), 5000);
        return () => clearInterval(int);
    }, []);
    return (
        <div>
            <p>
                An important update is available - please{' '}
                <Button variant="reset" onClick={() => window.location.reload(true)}>
                    reload this page
                </Button>{' '}
                as soon as possible
            </p>
            <p>This page will reload automatically in 5 seconds...</p>
        </div>
    );
};

export const OutdatedModal: React.FC = () => {
    const reload = useCallback(() => {
        window.location.reload(true);
    }, []);
    return (
        <section className={classes.outdated} onClick={reload}>
            <div className={classes.outdatedModal}>
                <h2 className={classes.outdatedTitle}>Update required</h2>
                <p className={classes.outdatedText}>
                    An important update is available - please{' '}
                    <Button variant="reset" onClick={reload}>
                        reload this page
                    </Button>{' '}
                    as soon as possible
                </p>
            </div>
        </section>
    );
};
