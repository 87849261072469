import React from 'react';
import { SearchAction, WebSite } from 'schema-dts';
import { usePathname } from 'src/hooks/router-hooks';
import { useDeps } from 'src/hooks/useDeps';

import { Schema } from '../Schema/Schema';

export const BrandSchema: React.FC = React.memo(() => {
    const { DOMAIN } = useDeps().env;
    const pathname = usePathname();
    return (
        <>
            <Schema
                input={
                    {
                        '@context': 'http://schema.org',
                        '@type': 'WebSite',
                        name: `Workwear Express`,
                        url: `https://www.workwearexpress.com/`,
                        ...((() => {
                            if (pathname !== '/') {
                                return undefined;
                            }
                            return {
                                potentialAction: {
                                    '@type': 'SearchAction',
                                    target: `https://${DOMAIN}/catalogsearch/results?query={search_term_string}` as any,
                                    'query-input': 'required name=search_term_string',
                                } as SearchAction,
                            };
                        })() as any),
                    } as WebSite
                }
            />
            <Schema
                input={
                    {
                        '@context': 'http://schema.org',
                        '@type': 'Organization',
                        name: `Workwear Express`,
                        url: `https://www.workwearexpress.com/`,
                        logo: `https://www.workwearexpress.com/static/icons/og-logo.jpg`,
                        sameAs: [
                            `https://www.facebook.com/Workwearexpressltd/`,
                            `https://www.instagram.com/workwearexpress/`,
                            `https://twitter.com/workwearexpress`,
                            `https://www.youtube.com/channel/UClvfQROSDFjbdXs75u6-QCg`,
                        ],
                    } as any
                }
            />
        </>
    );
});
