import { delay, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { Actions, AppMsg } from '../app.actions';

/**
 * Handle hiding alerts to the user
 * @param action$
 */
export function alertEpic(action$: Observable<any>): Observable<any> {
    return action$.pipe(
        ofType<Actions>('App.DismissAlert'),
        mergeMap(() => {
            return of(AppMsg('App.ClearAlert')).pipe(delay(300));
        }),
    );
}
