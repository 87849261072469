import React from 'react';
import { Helmet } from 'react-helmet-async';
import { contact } from 'src/config.json';
import { LazyPicture } from 'src/hooks/useLazyLoad';

import classes from './Header.scss';

const desktop = require('../../../static/img/logo-header-desktop.svg');
const mobile = require('../../../static/img/logo-header-mobile.svg');

export function Logo() {
    const onClick = () => {
        if (window.location.href.includes('/checkout/payment') || window.location.href.includes('/checkout/success')) {
            location.replace('/');
        }
    };

    return (
        <LazyPicture data-lazy-image data-alt={contact.name} className={classes.headerLogo} onClick={onClick}>
            <Helmet>
                <link rel="preload" href={desktop} as="image" />
            </Helmet>
            <source media="(min-width: 900px)" srcSet={desktop} />
            <source media="(max-width: 900px)" srcSet={mobile} />
        </LazyPicture>
    );
}
