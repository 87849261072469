import { Action } from 'redux';
import { ActionMap, createMsg } from 'action-typed';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import {
    CustomerDataCustomerExtensionInterface,
    CustomerDataCustomerInterface,
} from '@wearejh/swagger-rxjs/lib/Definitions';
import { UserState } from '@wearejh/m2-pwa-user/lib/user.reducer';

export type RegisterParams = {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    extension_attributes?: CustomerDataCustomerExtensionInterface & { [index: string]: any };
    token: string;
};

export type Messages = {
    ['User.SignIn']: {
        username: string;
        password: string;
        allowRedirects: boolean;
        beforeActions?: Action[];
        afterActions?: Action[];
        onErrorActions?: Action[];
    };
    ['User.SignOut']: undefined;
    ['User.Unauthenticated']: undefined;
    ['User.Refresh']: undefined;
    ['User.Persist']: undefined;
    ['User.Register']: RegisterParams;
    ['User.SignInError']: string;
    ['User.SignInSetToken']: { token: string };
    ['User.SetStatus']: DataStatus;
    ['User.SignInSuccess']: CustomerDataCustomerInterface;
    ['User.SetData']: CustomerDataCustomerInterface;
    ['User.Hydrate']: Pick<UserState, 'token' | 'session'>;
    ['User.Reset']: undefined;
    ['User.RefreshError']: string;
    ['User.RegisterError']: string;
    ['User.RegisterSuccess']: undefined;
    ['User.ShowRegister']: undefined;
    ['User.ShowLogin']: undefined;
    ['User.ShowForgot']: undefined;
};

export const Msg = createMsg<Messages>();

export type TypeMap = ActionMap<Messages>;
export type Actions = TypeMap[keyof TypeMap];
export type UserActions = Actions;
