import React, { useCallback, useEffect } from 'react';

/**
 * @param ref - The dom onto which the event listener will be added
 * @param predicate - the trigger to add the handler in the first place
 * @param cb - the callback to invoke if a document click happens outside
 */
export function useClickOutside(ref: React.RefObject<any>, predicate: boolean, cb: () => void) {
    /**
     * This will catch all clicks on the document, and then determine
     * if the target node was inside the 'ref' you provide as a param
     */
    const clickOutsideHandler = useCallback(
        (e) => {
            if (ref && ref.current !== null) {
                if (typeof ref.current!.contains === 'function') {
                    if (!ref.current.contains(e.target)) {
                        cb();
                    }
                }
            }
        },
        [cb, ref],
    );
    /**
     * A listener for the escape key
     */
    const escapeKeyHandler = useCallback(
        (e) => {
            e && e.keyCode === 27 && cb();
        },
        [cb],
    );

    /**
     * Setup the listeners
     */
    useEffect(() => {
        /**
         * If the predicate is false, prevent adding the listeners
         */
        if (!predicate) return;
        document.addEventListener('click', clickOutsideHandler);
        document.addEventListener('keyup', escapeKeyHandler);

        /**
         * Tear down functions for removing handlers
         */
        return () => {
            document.removeEventListener('click', clickOutsideHandler);
            document.removeEventListener('keyup', escapeKeyHandler);
        };
    }, [ref, predicate, clickOutsideHandler, escapeKeyHandler]);
}
