import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { alphabeticalStructure } from './utils/helpers';
import { NavSubMenu } from './NavSub';
import classes from './NavSub.scss';

type Props = {
    items: NavSubMenu[];
};

export const AlphabeticalNav: React.FC<Props> = ({ items }) => {
    return (
        <div className={classes.navSubContentWrapper}>
            <div key={items[0].title} className={classnames(classes.navSubContent, classes.navSubContentGrid)}>
                {items[0].children.length > 0 &&
                    alphabeticalStructure(items[0].children).map((el, i) => (
                        <div className={classes.navSubListWrapper} key={i}>
                            <p>{el.title}</p>
                            <ul className={classes.navSubList}>
                                {el.children.map((subSubItem, i) => (
                                    <li
                                        key={i}
                                        className={classnames(classes.navSubItem, {
                                            [classes.navSubItemNoBorder]: i === el.children.length - 1,
                                        })}
                                    >
                                        <Link to={subSubItem.url} className={classes.navSubLink}>
                                            {subSubItem.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
            </div>
        </div>
    );
};
