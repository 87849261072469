import React from 'react';

import { LazyImg } from '../../hooks/useLazyLoad';

import classes from './Spinner.scss';

type SpinnerProps = {
    showText?: boolean;
    loadingText?: string;
};

export const SPINNER = require('../../../static/img/spinner.gif');

export const Spinner: React.FC<SpinnerProps> = (props) => {
    return (
        <div className={classes.spinner}>
            <LazyImg src={SPINNER} alt="Please Wait..." />
            {props.showText && (
                <span className={classes.label}>{props.loadingText ? props.loadingText : 'Please wait...'}</span>
            )}
        </div>
    );
};

export const StaticSpinner: React.FC = () => {
    return <img src={SPINNER} alt="Please Wait..." style={{ width: '20px', height: '20px', margin: '0 10px' }} />;
};

Spinner.defaultProps = {
    showText: true,
};
