import React, { useState, useEffect } from 'react';

type Props = {
    initialTime: number;
    className?: string;
    title?: string;
};

const addZeros = (timer: string) => {
    const numbers = timer.split(':');

    const numbersWithZeros = numbers.map((number) => {
        if (parseInt(number) < 10) return '0' + number;
        return number;
    });

    return numbersWithZeros.join(':');
};

const formatTime = (time: number) => {
    let seconds = time;
    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    const hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    return addZeros(`${days > 0 ? days + ':' : ''}${hrs}:${minutes}:${seconds}`);
};

export const Timer: React.FC<Props> = ({ initialTime, className, title }) => {
    const [timerTime, setTimerTime] = useState(initialTime);

    useEffect(() => {
        if (timerTime > 0) {
            const interval = setInterval(() => {
                setTimerTime(timerTime - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [timerTime]);

    if (timerTime <= 0) return null;

    return (
        <span className={className} data-testid="timer">
            {title} {formatTime(timerTime)}
        </span>
    );
};
