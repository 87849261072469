import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { useCallback } from 'react';
import { StoreState } from 'src/types/global-types';
import { shallowEqual, useSelector } from 'react-redux';
import Bugsnag from '@bugsnag/js';

import { useObservableValue } from './useObservableValue';

export enum Breakpoint {
    Mob = 'Mob',
    Desk = 'Desk',
}

const MQ = '(max-width: 899px)';

export function useBreakpoint(mediaQuery: string = MQ, name = 'useBreakpoint'): [Breakpoint, any] {
    const mql = (() => {
        if (typeof window !== 'undefined') {
            return window.matchMedia(mediaQuery).matches;
        }
        return true;
    })();

    const cb = useCallback(() => {
        return new Observable((obs) => {
            const mql = window.matchMedia(mediaQuery);

            function screenTest(e) {
                if (e.matches) {
                    obs.next(true);
                } else {
                    obs.next(false);
                }
            }

            try {
                // Chrome & Firefox
                mql.addEventListener('change', screenTest);
            } catch (e1) {
                try {
                    // Safari
                    mql.addListener(screenTest);
                } catch (e2) {
                    Bugsnag.notify(e2);
                }
            }

            screenTest(mql);
            return () => {
                try {
                    // Chrome & Firefox
                    mql.removeEventListener('change', screenTest);
                } catch (e1) {
                    try {
                        // Safari
                        mql.removeListener(screenTest);
                    } catch (e2) {
                        Bugsnag.notify(e2);
                    }
                }
            };
        }).pipe(
            debounceTime(500),
            map((matches) => (matches ? Breakpoint.Mob : Breakpoint.Desk)),
        );
    }, [mediaQuery]);

    return useObservableValue<never, Breakpoint>(cb, mql ? Breakpoint.Mob : Breakpoint.Desk, name);
}

export function useBreakpointMatch() {
    const [bp] = useBreakpoint();
    return {
        isMob: bp === Breakpoint.Mob,
        isDesk: bp === Breakpoint.Desk,
    };
}

const selector = (s: StoreState) => s.app.renderEnv;
export function useRenderEnv() {
    const renderEnv = useSelector(selector, shallowEqual);
    return {
        isBrowser: renderEnv === 'browser',
        isServer: renderEnv === 'server',
    };
}
