import { ActionMap, createMsg } from 'action-typed';
import {
    BasketCustomisations,
    BasketItemRemoveCustomisation,
} from 'src/components/CustomisationPage/feature/customisation.types';

import { BasketCustomisationsOnRefresh, BasketState } from './basket.reducer';
import { Step } from './basket.types';

export type Messages = {
    ['Basket.LoadStep']: Step;
    ['Basket.Refresh']: undefined;
    ['Basket.RefreshSuccess']: BasketCustomisationsOnRefresh;
    ['Basket.RefreshError']: string;
    ['Basket.RemoveCustomisations']: BasketItemRemoveCustomisation[];
    ['Basket.RemoveCustomisationsSuccess']: undefined;
    ['Basket.RemoveCustomisationsError']: string;

    ['Basket.CopyCustomisations']: BasketCustomisations;
    ['Basket.CopyCustomisationsSuccess']: undefined;
    ['Basket.CopyCustomisationsError']: string;
    ['Basket.Noop']: string | undefined;
};

export const Msg = createMsg<Messages>();
export const BasketMsg = Msg;
export type TypeMap = ActionMap<Messages>;
export type Actions = TypeMap[keyof TypeMap];
export type AppendBasketState<T = Record<string, unknown>> = T & { basket: BasketState };
