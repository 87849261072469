import React from 'react';
import { Link } from 'react-router-dom';

import { Logo } from './Logo';
import classes from './Header.scss';

export const HeaderLogo: React.FC = React.memo(() => {
    return (
        <Link to="/" className={classes.headerLogoLink}>
            <Logo />
        </Link>
    );
});
