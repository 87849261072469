import { Deps } from 'src/types/global-types';

export function unimplementedAction(name: string) {
    return function () {
        console.warn(`${name} not implemented`);
    };
}

export function unimplementedService(name: string) {
    return function () {
        return Promise.reject(new Error(`${name} not implemented`));
    };
}

export function depsToDepsWithCspToken(
    token: string,
    deps: Deps,
): Pick<Deps, 'apiUrl' | 'postJSON' | 'getJSON' | 'restHeaders'> {
    return {
        apiUrl: deps.apiUrl,
        postJSON: deps.postJSON,
        getJSON: deps.getJSON,
        restHeaders: (incoming) => {
            const prev = deps.restHeaders(incoming);
            return {
                ...prev,
                Authorization: `Bearer ${token}`,
            };
        },
    };
}
