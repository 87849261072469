import React from 'react';
import { useFieldError } from '@wearejh/rx-form/lib/hooks/useFieldError';

import defaultErrors from '../../error-messages.json';

import classes from './Forms.scss';

type Props = {
    field: string;
    render?: (node: React.ReactNode) => React.ReactNode;
    errors?: Record<string, Record<string, string>>;
};

export function ErrorFor(props: Props) {
    const { errors = defaultErrors, render, field } = props;
    const error = useFieldError(field);

    const inner = (() => {
        if (error) {
            if (errors[field] && errors[field][error]) {
                return <ErrorText text={errors[field][error]} />;
            }
            return <ErrorText text={error} />;
        }
        return null;
    })();

    if (error && inner) {
        if (render) {
            return render(inner) as any;
        }
        return inner;
    }

    return null;
}

export const ErrorText: React.FC<{ text: string }> = (props) => {
    return (
        <div className={classes.inputError}>
            <span className={classes.inputErrorText}>{props.text}</span>
        </div>
    );
};
