import React from 'react';
import { Certificates } from 'src/components/Certificates/Certificates';
import { FooterAction } from 'src/components/Footer/FooterAction';

import { LazyPicture } from '../../hooks/useLazyLoad';
import { contact } from '../../../../pwa/src/config.json';

import classes from './FooterCta.scss';

type FooterCtaProps = Record<string, unknown>;

export const FooterCta: React.FC<FooterCtaProps> = React.memo(() => {
    return (
        <div className={classes.footerCta}>
            <LazyPicture data-alt={contact.name} className={classes.footerCtaLogo}>
                <source srcSet={require('../../../static/img/logo-header-desktop-black.svg')} />
            </LazyPicture>
            <FooterAction className={classes.footerCtaAction} />

            <Certificates isFooter className={classes.ctaCertificates} />
        </div>
    );
});
