import React, { useCallback, useContext, useMemo } from 'react';

import { ConfigContext } from '../AppShell/ConfigProvider';

import classes from './Controls.scss';
import { HeaderStoreDropdown } from './Dropdown';

/**
 * Currency selector
 */
export const CurrencySelect = React.memo(() => {
    /**
     * Bring in state from global context
     */
    const { currentCurrency, availableCurrencies, setCurrency } = useContext(ConfigContext);

    /**
     * Handler for selecting a currency
     */
    const selectCurrency = useCallback(
        (code) => {
            setCurrency(code);
        },
        [setCurrency],
    );

    /**
     * Filter the available currencies to NOT
     * include the currently selected one.
     */
    const filtered = useMemo(() => {
        return availableCurrencies.filter((x) => x.code !== currentCurrency.code);
    }, [availableCurrencies, currentCurrency.code]);

    return (
        <HeaderStoreDropdown
            data-test-id="CurrencySelect"
            text={currentCurrency.code || '...'}
            items={filtered}
            render={({ item, close }) => {
                return (
                    <button
                        type="button"
                        className={classes.controlListButton}
                        onClick={() => {
                            selectCurrency(item.code);
                            close();
                        }}
                    >
                        {item.code}
                    </button>
                );
            }}
        />
    );
});
