import React from 'react';

import { Button, ButtonProps } from './Button';

interface ButtonLinkProps extends ButtonProps {
    to: string | { [index: string]: any };
}

export const ButtonLink: React.FC<ButtonLinkProps> = (props) => {
    const { to, ...restProps } = props;
    return <Button {...restProps} element="a" to={to} />;
};

ButtonLink.defaultProps = {
    variant: 'primary',
};
