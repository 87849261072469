import { userSignedInReaction } from 'src/features/cart/epics/userSignedInReaction.epic';
import { cartFetch2 } from 'src/features/cart/epics/cartFetch2.epic';
import { cartRegister as baseCartRegister } from '@wearejh/m2-pwa-cart-gql/lib/cart.register';

export function cartRegister() {
    return baseCartRegister({
        userSignedInReaction,
        cartFetch: cartFetch2,
    } as any);
}
