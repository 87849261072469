import { userRegister as baseUserRegister } from 'src/features/user/user.legacy.register';
import { userReducer } from '@wearejh/m2-pwa-user';

const NAME = 'user';

function userRegisterEnhanced(overrides?) {
    const mergedEpics = {
        ...baseUserRegister().epics,
        ...overrides,
    };
    return {
        epics: Object.keys(mergedEpics).map((key) => mergedEpics[key]),
        reducers: { [NAME]: userReducer },
        name: NAME,
    };
}

export function userRegister() {
    return userRegisterEnhanced({} as any);
}
