import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
    enabled: boolean;
    id: string | null;
};

export const GoogleTagManager: React.FC<Props> = (props) => {
    const [scripts, setScripts] = useState<{ script: string; noscript: string } | null>(null);

    /**
     * Trigger the scripts to be added once
     */
    useEffect(() => {
        const win = window as any;
        win.dataLayer = win.dataLayer || [];
        win.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });
        setScripts({
            script: 'https://www.googletagmanager.com/gtm.js?id=' + props.id,
            noscript: 'https://www.googletagmanager.com/ns.html?id=' + props.id,
        });
    }, [props.id]);

    /**
     * Prevent the script from being added immediately - we
     * want time to create the datalayer etc before we add it
     */
    if (!scripts) return null;

    return (
        <Helmet>
            <script src={scripts.script} />
            <noscript>{`
                <iframe src={fallback} height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
                    &nbsp;
                </iframe>
            `}</noscript>
        </Helmet>
    );
};
