import { onError } from 'apollo-link-error';

export function isOutOfBoundsError(errors?: any[]): boolean {
    if (!errors) return false;
    if (!Array.isArray(errors)) return false;
    const isPageError = errors.find((x) => /currentPage value ([\d]+)? specified is greater/.test(x.message));
    return Boolean(isPageError);
}

export const outOfBoundsErrorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        if (isOutOfBoundsError(graphQLErrors as any[])) {
            console.warn('GQL: resetting a query to handle out of bounds');
            operation.variables = { ...operation.variables, currentPage: 1 };
            return forward(operation);
        }
    }
});
