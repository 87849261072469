import { ignoreElements, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { concat, EMPTY, Observable, of } from 'rxjs';
import { Actions } from '@wearejh/m2-pwa-user/lib/user.actions';
import { cartItems, cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { execute } from 'swagger/ts/WorkwearExpressBasketCartManagementV1SwapCustomerCartsPost';
import { catchableUnauthenticated } from '@wearejh/m2-pwa-user/lib/utils/user.utils';
import { Deps } from 'src/types/global-types';
import { createDebug } from 'src/util/debug';
import { fetchMineCartId } from '@wearejh/m2-pwa-cart-gql/lib/utils/fetchMineCartId';
import { fetchCart } from '@wearejh/m2-pwa-cart-gql/lib/utils/fetchCart';
import { Msg } from '@wearejh/m2-pwa-cart-gql/lib';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import Bugsnag from '@bugsnag/js';
const debug = createDebug('userSignedInReaction');

/**
 * Listen for the user signing in
 *
 * When that happens, attempt to refresh the cart
 *
 * @param action$
 * @param state$
 * @param deps
 */
export function userSignedInReaction(action$: Observable<any>, state$: Observable<any>, deps: Deps) {
    return action$.pipe(
        ofType<Actions>('User.SignInSuccess'),
        withLatestFrom(cartItems(state$), cartVariantAndId(state$)),
        mergeMap(([, items, [, cartId]]) => {
            const before = of(Msg('Cart.SetStatus', DataStatus.Pending));
            const action = refreshCart(items, cartId!, deps);
            const after = of(Msg('Cart.SetStatus', DataStatus.Success));
            return concat(before, action, after);
        }),
    );
}

function refreshCart(items: any[], cartId: string | number, deps: Deps) {
    /**
     * If guest cart has 0 items, always refresh the cart from the user
     */
    if (items.length === 0) {
        return mineFetch(deps);
    }

    /**
     * If there ARE items in the guest cart, we'll always use those
     * and wipe the current account cart
     */
    const ajax$: Observable<any> = execute(
        {
            guestCartId: String(cartId),
        },
        deps,
    ).pipe(
        tap(() => debug('transferred guest cart to account card')),
        ignoreElements(),
    );
    return concat(ajax$, mineFetch(deps));
}

function mineFetch(deps: Deps) {
    return fetchMineCartId(deps).pipe(
        switchMap((id) => fetchCart(id, deps)),
        switchMap((resp) => {
            const FETCH_SUCCESS = Msg('Cart.FetchSuccess', resp);
            const PERSIST = Msg('Cart.Persist');
            return of(FETCH_SUCCESS, PERSIST);
        }),
        catchableUnauthenticated(({ error }) => {
            Bugsnag.notify(error);
            return EMPTY;
        }),
    );
}
