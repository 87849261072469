import { Store } from 'redux';
require('intersection-observer');
import Bugsnag from '@bugsnag/js';
import { createHttpLink } from 'apollo-link-http';
import React from 'react';
import './scss/index.scss';
import { hydrate, render } from 'react-dom';
import { initBrowserApollo, APP_ENV_STATE_ID } from '@wearejh/m2-pwa-engine/lib/browser/initBrowserApollo';
import { userRegister } from 'src/features/user/user.register';
import { cspRegister, CspSession } from 'src/components/CustomerServicePortal/feature/csp.register';
import { readJson } from '@wearejh/m2-pwa-engine/lib/utils/readJson';
import { createCurrencyBrowser } from '@wearejh/m2-pwa-currency/lib/browser';
import { cartRegister } from 'src/features/cart/cart.register';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'src/components/Bugsnag/BugsnagClient';
import { SmartlookBoundary } from 'src/components/Smartlook/SmartlookBoundary';

import { appRegister } from './features/app/app.register';
import { initialState } from './features/app/app.reducer';
import { ErrorComponent } from './components/Error/ErrorComponent';
import { Outdated } from './components/Outdated/Outdated';
import { NotFound } from './components/NotFound/NotFound';
import { paths, text } from './config.json';
import urlResolver from './queries/urlResolver.graphql';
import { knownRoutes } from './routes';
import { apiUrl } from './util/apiUrl';
import { AppShell } from './components/AppShell/AppShell';
import { suppressGqlErrors } from './components/Banner/links/missing-cms.link';
import { outOfBoundsErrorLink } from './components/CategoryListingPage/links/productList.links';
import getCartQuery from './queries/getCart.graphql';

const { link: currencyLink, register: registerCurrency, appendHeader: appendCurrencyHeader } = createCurrencyBrowser();

const { links, ...rest } = appRegister();

/**
 * In production, the env vars will come from the in-page JSON
 * but in development we can import the vars
 */
const appEnv = readJson(APP_ENV_STATE_ID);

const apiBase = location.origin + '/graphql';

let httpLink = createHttpLink({
    uri: apiBase,
    useGETForQueries: true,
});

/**
 * If we're in a testing environment, use a different
 * HTTP link to resolve GQL queries
 */
if (process.env.NODE_ENV === 'production') {
    /** noop **/
} else {
    if (window.Cypress) {
        httpLink = require('./features/cypress/http-link').createHttpLink({
            uri: apiBase,
            useGETForQueries: true,
        });
    }
}

let reduxStore: null | Store<any> = null;

const createApp = initBrowserApollo({
    unionAndInterfaceTypes: require('../__generated__/UNION_AND_INTERFACES.json'),
    urlResolver,
    env: appEnv,
    knownRoutes,
    deps: {
        apiUrl,
        paths,
        text,
        restHeaders: (incoming) => {
            const cspHeaders = {};
            try {
                if (reduxStore) {
                    const session = reduxStore.getState().csp?.session;
                    const isCsp = session === CspSession.SignedIn;
                    if (isCsp) {
                        cspHeaders['wwe-cs-portal'] = true;
                    }
                }
            } catch (e) {
                Bugsnag.notify(e);
            }
            const outgoing = {
                ...incoming,
                ...appendCurrencyHeader({}).headers,
                ...cspHeaders,
            };
            return outgoing;
        },
        queries: {
            getCart: getCartQuery,
        },
    },
    asyncLoader: (componentName: string) =>
        import(/* webpackChunkName: "[request]_root" */ `./RootComponents/${componentName}`),
    resolveWeakLoader: (componentName: string) => (require as any).resolveWeak(`./RootComponents/${componentName}`),
    features: [rest, userRegister(), cspRegister(), cartRegister(), registerCurrency()],
    links: [outOfBoundsErrorLink, suppressGqlErrors, currencyLink, ...links, httpLink],
    initialState: {
        app: {
            ...initialState,
            online: window.navigator.onLine,
            renderEnv: 'browser',
        },
    },
    components: {
        ErrorComponent: ErrorComponent,
        OfflineComponent: ErrorComponent,
        OutdatedComponent: Outdated,
        NotFoundComponent: NotFound,
    },
});

const fn = process.env.NODE_ENV === 'production' ? hydrate : render;

const { App, Async, store } = createApp();
reduxStore = store;

fn(
    <ErrorBoundary>
        <App>
            <SmartlookBoundary>
                <HelmetProvider>
                    <AppShell>{Async}</AppShell>
                </HelmetProvider>
            </SmartlookBoundary>
        </App>
    </ErrorBoundary>,
    document.getElementById('root'),
);
