import { Observable, concat, of, race, asyncScheduler, scheduled } from 'rxjs';
import { switchMap, mergeMap, map, take, takeUntil } from 'rxjs/operators';
import { execute as createAccountAjax } from 'src/features/user/lib/CustomerAccountManagementV1CreateAccountPost';
import { ofType } from 'redux-observable';
import { EpicDeps } from '@wearejh/m2-pwa-engine/lib/types';
import { catchable } from '@wearejh/swagger-rxjs/utils/ajax-helpers';
import { LOCATION_CHANGE } from '@wearejh/m2-pwa-engine/lib/router';
import { Msg, TypeMap, Actions, UserActions } from 'src/features/user/lib/user.action';

export function createAccountEpic(action$: Observable<any>, _state$, deps: EpicDeps) {
    return action$.pipe(
        ofType<Actions, TypeMap['User.Register']>('User.Register'),
        switchMap(({ payload }) => {
            const { email, firstname, lastname, password, extension_attributes = {}, token } = payload;

            const ajax$ = createAccountAjax(
                {
                    password,
                    customer: {
                        firstname,
                        lastname,
                        email,
                        extension_attributes,
                    },
                    token,
                },
                deps,
            );

            return ajax$.pipe(
                mergeMap(() => {
                    return concat(
                        // now sign the user in
                        of(
                            Msg('User.SignIn', {
                                username: email,
                                password: password,
                                beforeActions: [],
                                afterActions: [],
                                onErrorActions: [],
                                allowRedirects: true,
                            }),
                        ),

                        // now listen for the result of the sign-in
                        race(
                            // if the account + sign-in was successful
                            action$.pipe(
                                ofType<UserActions>('User.SignInSuccess'),
                                mergeMap(() => {
                                    return scheduled(of(Msg('User.RegisterSuccess')), asyncScheduler);
                                }),
                            ),
                            action$.pipe(
                                ofType<UserActions, TypeMap['User.SignInError']>('User.SignInError'),
                                map(({ payload }) => Msg('User.RegisterError', payload)),
                            ),
                        ).pipe(take(1), takeUntil(action$.pipe(ofType(LOCATION_CHANGE)))),
                    );
                }),
                catchable(({ error }) => of(Msg('User.RegisterError', error))),
            );
        }),
    );
}
