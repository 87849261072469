import React from 'react';
import { useErrorCount } from '@wearejh/rx-form/lib/hooks/useErrorCount';
import classnames from 'classnames';
import { LazyImg } from 'src/hooks/useLazyLoad';

import { Button } from '../Button/Button';
import { Svg } from '../Svg/Svg';

import classes from './Forms.scss';

type ActionsProps = {
    primaryText: string;
    primaryVariant?: 'primary' | 'secondary' | 'bold' | 'light' | 'alert' | 'reset';
    primary?: React.ReactNode;
    primaryDisabled?: boolean;
    secondary?: React.ReactNode;
    variant?: 'inline' | 'space-between';
    direction?: 'row' | 'reverse';
    icon?: string;
    className?: string;
    selectedNr?: number;
    error?: string | null;
};

export const Actions: React.FC<ActionsProps> = ({
    children,
    primaryText,
    primaryVariant,
    primaryDisabled,
    variant,
    direction,
    primary,
    secondary,
    icon,
    className,
    selectedNr,
    error,
}) => {
    const count = useErrorCount();
    const displayNumber = selectedNr !== undefined && selectedNr >= 0;

    return error ? (
        <ErrorContainer text="Please select a delivery method before continuing" />
    ) : (
        <>
            <div
                className={classnames(className, classes.actions, {
                    [classes.actionsAlt]: variant === 'space-between',
                    [classes.actionsReverse]: direction === 'reverse',
                })}
            >
                <div className={classes.actionsPrimary}>
                    {displayNumber && <span className={classes.optionalText}>{`${selectedNr} selected`}</span>}

                    {primary && primary}
                    {!primary && (
                        <Button
                            variant={primaryVariant}
                            type="submit"
                            data-test-id="Actions_primary_action"
                            disabled={primaryDisabled}
                        >
                            {icon && (
                                <img
                                    src={require(`src/components/Svg/svgs/${icon}.svg`)}
                                    alt="Flash icon"
                                    style={{ width: '12px', transform: 'translate(-5px, 2px)' }}
                                />
                            )}
                            <span className={classes.optionalMobile}>
                                {displayNumber && `${selectedNr} selected -`}
                            </span>
                            {primaryText}
                        </Button>
                    )}
                </div>
                {(children || secondary) && (
                    <div
                        className={classnames({
                            [classes.actionsSecondary]: true,
                            [classes.actionsSecondaryReverse]: direction === 'reverse',
                        })}
                    >
                        {children || secondary}
                    </div>
                )}
            </div>
            {count > 0 && <ErrorSummaryText count={count} />}
        </>
    );
};

export const ErrorSummaryText: React.FC<{ count: number }> = (props) => {
    const text = props.count === 1 ? 'There is an error' : `There are ${props.count} errors`;
    return (
        <p className={classes.actionsErrorCount}>
            <span>
                <Svg name="close" /> {text}
            </span>
        </p>
    );
};

export const ErrorContainer: React.FC<{ text: string }> = ({ text }) => (
    <div className={classes.errorContainer}>
        <LazyImg src={require('../../../static/icons/errorIcon.svg')} />
        <div className={classes.content}>{text}</div>
    </div>
);

Actions.defaultProps = {
    primaryVariant: 'primary',
    variant: 'inline',
    direction: 'row',
};
