import React from 'react';
import { StoreState } from 'src/types/global-types';
import { shallowEqual, useSelector } from 'react-redux';
import { paths } from 'src/config.json';
import { HeaderProvider } from 'src/components/Header/Header';
import { Usps } from 'src/components/Usps/Usps';
import { HeaderCheckout } from 'src/components/Header/HeaderCheckout';
import { BannerQuery } from 'src/components/Banner/BannerQuery';
import { SearchLoader } from 'src/components/Algolia/SearchDropdown/SearchLoader';

import classes from './Header.scss';

type Props = Record<string, unknown>;

function stateSelector(state: StoreState) {
    return {
        componentName: state.router.current?.componentName,
        pathname: state.router.location.pathname,
    };
}

const staticPages = ['/bulk-and-corporate'];

export const HeaderVariant: React.FC<Props> = () => {
    const state = useSelector(stateSelector, shallowEqual);

    const staticPage = staticPages.includes(state.pathname);
    if (staticPage) {
        return null;
    }

    if (state.pathname.indexOf(paths.checkout.checkout) === 0) {
        return <HeaderCheckout />;
    }
    return (
        <div id="header" className={classes.stickyHeader}>
            <HeaderProvider />
            <Usps />
            <BannerQuery />
            <div id="ProductDetailSidebarPortal" className={classes.productDetailSidebarPortal} />
            <div className={classes.searchMobile}>
                <SearchLoader />
            </div>
        </div>
    );
};
