import { stripDomain } from 'src/util/sanitizeLink';

export function formatUrl(link = ''): string {
    return `/${link.replace(/^\//, '')}`;
}

export function imagePath(input?: string | null): string {
    const placeholder = require('../../static/img/placeholder.jpg');
    if (!input) return placeholder;
    if (input.includes('placeholder/small_image.jpg')) {
        return placeholder;
    }
    if (input.includes('placeholder/image.jpg')) {
        return placeholder;
    }
    if (input.includes('product/placeholder')) {
        return placeholder;
    }
    if (input.startsWith('https://placehold.it')) {
        return input;
    }
    return stripDomain(input);
}

export function maybePdf(input?: any): string {
    const isPdf = input && typeof input === 'string' && input.match(/\.pdf$/);
    return isPdf ? require('../../static/img/pdf-icon-01.png') : input;
}
