import React from 'react';
import { useVatCausesRender } from 'src/components/AppShell/VatProvider';
import classnames from 'classnames';

import classes from './Controls.scss';

type Props = {
    isFooter?: boolean;
};

export const VatSelect: React.FC<Props> = React.memo(({ isFooter }) => {
    /**
     * Bring in state from global context
     */
    const { setVat, vat } = useVatCausesRender();

    /**
     * Filter the available currencies to NOT
     * include the currently selected one.
     */

    return (
        <div
            className={classnames({
                [classes.footerSelectContainer]: isFooter,
            })}
        >
            <span
                className={classnames(classes.vat, {
                    [classes.footerInc]: isFooter,
                    [classes.active]: vat,
                })}
            >
                {!isFooter ? 'inc vat' : 'Include VAT'}
            </span>
            <button
                className={classnames(classes.controlVat, {
                    [classes.controlVatFooter]: isFooter,
                })}
                type="button"
                onClick={() => setVat(!vat)}
            >
                {vat ? (
                    <img src={require('../../../static/icons/inc-vat.svg')} alt="need help icon" />
                ) : (
                    <img src={require('../../../static/icons/exc-vat.svg')} alt="need help icon" />
                )}
            </button>
            <span
                className={classnames(classes.vat, {
                    [classes.footerExc]: isFooter,
                    [classes.active]: !vat,
                })}
            >
                {!isFooter ? 'exc vat' : 'Exclude VAT'}
            </span>
        </div>
    );
});
