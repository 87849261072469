import React from 'react';
import { TwoCol } from 'src/components/Layout';
import { SkeletonBlock } from 'src/elements/Skeletons/SkeletonBlock';
import { Modal } from 'src/components/Modal/Modal';
import { ModalHeader } from 'src/components/Modal/ModalHeader';
import { ModalBody } from 'src/components/Modal/ModalBody';
import { ModalFooter } from 'src/components/Modal/ModalFooter';
import classnames from 'classnames';

import classes from './Skeletons.scss';

export const ProductPageSkeleton = () => {
    return (
        <div className={classes.productPageSkeleton}>
            <div className={classes.productPageSkeletonImageWrapper}>
                <SkeletonBlock className={classes.productPageSkeletonImage} />
            </div>
            <div className={classes.productPageSkeletonInfo}>
                <SkeletonBlock style={{ height: '40px', marginBottom: '20px', maxWidth: '300px' }} />
                <SkeletonBlock style={{ height: '35px', marginBottom: '20px' }} />
                <SkeletonBlock style={{ height: '15px', width: '150px' }} />
            </div>
        </div>
    );
};

export const HomeMainWidgetsSkeleton = () => (
    <div className={classes.homeMainWidgetsSkeleton}>
        <SkeletonBlock style={{ height: '390px' }} />
        <SkeletonBlock style={{ height: '620px' }} />
        <SkeletonBlock style={{ height: '200px' }} />
    </div>
);

export const BundleProductSkeleton = () => {
    return (
        <div className={classes.bundleProductSkeleton}>
            <SkeletonBlock style={{ height: '170px', marginBottom: '20px', maxWidth: '1200px' }} />
        </div>
    );
};

export const BundlePageSkeleton = () => <SkeletonBlock className={classes.bundleSkeletonContent} />;

export const SingleBundleSkeleton = () => <SkeletonBlock className={classes.singleBundleSkeleton} />;

export const ProductPickerSkeleton = ({ className }) => (
    <SkeletonBlock className={classnames(classes.productPickerSkeleton, className)} />
);

export const OptionsSkeleton = () => {
    return (
        <div>
            <SkeletonBlock style={{ height: '100px', marginBottom: '20px', maxWidth: '300px' }} />
            <SkeletonBlock style={{ height: '35px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ height: '35px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ height: '35px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ height: '25px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ height: '25px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ height: '15px', width: '150px' }} />
        </div>
    );
};

export const BannerSkeleton = () => {
    return (
        <div className={classes.bannerSkeleton}>
            <SkeletonBlock className={classes.banner} />
        </div>
    );
};

export const NavSkeleton = () => {
    return (
        <div className={classes.navSkeleton}>
            <SkeletonBlock className={classes.nav} />
        </div>
    );
};

export const CategoryRootSkeleton = () => {
    return <TwoCol main={<Line2 />} left={<Line />} />;
};

export const Line = () => {
    return (
        <>
            <SkeletonBlock style={{ width: '100%', height: '25px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ width: '70%', height: '25px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ width: '40%', height: '25px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ width: '40%', height: '25px', marginBottom: '20px' }} />
        </>
    );
};

const Line2 = () => {
    return (
        <>
            <SkeletonBlock style={{ width: '100%', height: '45px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ width: '70%', height: '45px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ width: '40%', height: '45px', marginBottom: '20px' }} />
            <SkeletonBlock style={{ width: '40%', height: '45px', marginBottom: '20px' }} />
        </>
    );
};

export const CustomizationSkeleton = () => {
    return (
        <>
            <SkeletonBlock style={{ width: '100%', height: '25px', marginBottom: '10px' }} />
            <SkeletonBlock style={{ width: '70%', height: '25px', marginBottom: '10px' }} />
            <SkeletonBlock style={{ width: '40%', height: '25px', marginBottom: '10px' }} />
            <SkeletonBlock style={{ width: '40%', height: '25px', marginBottom: '10px' }} />
        </>
    );
};

export const Skeleton = () => {
    return (
        <>
            <SkeletonBlock style={{ width: '100%', height: '25px', marginBottom: '10px' }} />
            <SkeletonBlock style={{ width: '70%', height: '25px', marginBottom: '10px' }} />
            <SkeletonBlock style={{ width: '40%', height: '25px', marginBottom: '10px' }} />
            <SkeletonBlock style={{ width: '40%', height: '25px', marginBottom: '10px' }} />
        </>
    );
};

export const ImageSkeleton = () => {
    return (
        <div className={classes.imageSkeleton}>
            <SkeletonBlock className={classes.image} />
        </div>
    );
};

export const AtbModalSkeleton: React.FC<{ close(): void }> = (props) => {
    return (
        <Modal type="popup" isOpen={true} onDismiss={props.close}>
            <ModalHeader title="..." icon="processing" type="success" onDismiss={props.close} />
            <ModalBody>
                <SkeletonBlock style={{ height: '20px', marginBottom: '20px' }} />
                <SkeletonBlock style={{ height: '20px', marginBottom: '20px' }} />
                <SkeletonBlock style={{ height: '20px', marginBottom: '20px' }} />
            </ModalBody>
            <ModalFooter onDismiss={props.close} cancelText="Continue Shopping" />
        </Modal>
    );
};

export const RelatedProductsSkeleton = () => {
    return (
        <div className={classes.relatedProductsSkeleton}>
            <SkeletonBlock style={{ height: '330px', marginTop: '30px', maxWidth: '660px' }} />
        </div>
    );
};
